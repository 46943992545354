(function () {
  var pieHelper = {
    padding_200: 200,
    padding_84: 84,
    padding_70: 70,
    padding_60: 60,
    padding_40: 40,
    padding_25: 25,
    padding_20: 20,
    padding_35: 35,
    padAngleSmall: 0.10,
    padAngleNormal: 0.05
  };
  
  var colorHelper = {
    restful: '#6BFCAC',
    restless: '#FFC511',
    outOfBed: '#F84B20',
    black: '#000000',
    gradient: 'url(#svgGradient)',
    gray: '#8A8C91',
    text: '#F2F5F7',
    editedLabel: '#99ABBD'
  };

  var session = {};
  var type = '';
  var id;
  var isReport;
  var sleepTrendsType;

  window.setSleepGaugeChartData = function(idData, sessionData, typeData, isReportData, sleepTrendsTypeData) {
    session = sessionData;
    type = typeData;
    id = idData;
    isReport = isReportData;
    sleepTrendsType = sleepTrendsTypeData;

    drawChart(session, type);
  }

  function drawChart(sessions, type) {
   d3.selectAll(`#${id}` + ' svg').remove();
    const svg = d3.select(`#${id}`).append('svg')
      .attr('width', getDimension())
      .attr('height', getDimension() + (isEditedSession() ? pieHelper.padding_35 : 0))
      .attr('xmlns', 'http://www.w3.org/2000/svg')
      .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
      .attr('xmlns:svgjs', 'http://svgjs.com/svgjs');

    const pieData = {};

    if (sessions && sessions.restful) {
      pieData['restful'] = sessions.restful;
    }

    if (sessions &&  sessions.restless) {
      pieData['restless'] = sessions.restless;
    }

    if (sessions &&  sessions.outOfBed) {
      pieData['outOfBed'] = sessions.outOfBed;
    }

    const colorRange = [];

    Object.keys(pieData).forEach(key => {
      if (pieData[key]) {
        colorRange.push(colorHelper[key]);
      }
    });

    const color = d3
      .scaleOrdinal()
      .range(colorRange);

    const pie = d3
      .pie()
      .value((d) => d[1])
      .padAngle(type === 'extraSmall' ? pieHelper.padAngleSmall : pieHelper.padAngleNormal);

    const data_ready = pie(Object.entries(pieData)).filter(data => data.data[1]);

    const arc = d3.arc()
      .outerRadius(getInnerRadius())
      .innerRadius(getOuterRadius());

    const translation = getTranslation();

    svg
      .selectAll('pies')
      .data(data_ready)
      .enter()
      .append('path')
      .attr(
        'transform',
        `translate(${translation},${translation})`
      )
      .attr('d', arc)
      .attr('fill', (d) => color(d.data[0]))
      .attr('stroke', (d) => color(d.data[0]));

    const defs = svg.append('defs');

    const gradient = defs.append('linearGradient')
      .attr('id', 'svgGradient')
      .attr('x1', '0%')
      .attr('x2', '100%')
      .attr('y1', '0%')
      .attr('y2', '100%');

    gradient.append('stop')
      .attr('class', 'start')
      .attr('offset', '0%')
      .attr('stop-color', '#ffffff')
      .attr('stop-opacity', 1);

    gradient.append('stop')
      .attr('class', 'end')
      .attr('offset', '100%')
      .attr('stop-color', '#c5f5f3')
      .attr('stop-opacity', 1);

    if(showSleepIQScoreLabel()) {
      // Append SleepIQ score label
      const labelYPosition = type === 'extraBig' ? -30 : -20;
      const siqLabel = appendText(svg, translation, '16px', 400, labelYPosition, 'SleepIQ');
      siqLabel.append('tspan')
      .text('®')
      .attr('dy', '-6')
      .attr('dx', '1')
      .style('font-size', '9px')
      .append('tspan').text(' score').attr('dy', '6').style('font-size', '16px');
    }

    // Append SleepIQ score value in the middle 
    if(sessions) {
      appendText(svg, translation, getFontSize(), 900, getYPosition(), sessions.sleepQuotient ? sessions.sleepQuotient: sessions.sleepIQScore);
    }

    if(isEditedSession()) {
      // Append Edited label
      svg
        .append("text")
        .attr("x", getDimension() / 2 - pieHelper.padding_20) // 20 is half of the "Edited" label width
        .attr("y", pieHelper.padding_200)
        .text("Edited")
        .style('fill', colorHelper.editedLabel)
        .style('font-family', 'Avenir_400')
        .style('font-size', '14px')
        .style('line-height', '18px')
        .style('font-weight', 400)
        .exit().remove();
    }
  }

  function getDimension() {
    switch(type) {
      case 'extraBig':
        return 168;
      case 'big':
        return 141;
      case 'mediumBig':
        return 120;
      case 'medium':
        return 80;
      case 'small':
        return 48;
      case 'extraSmall':
        return 40;
    }
  }

  function getTranslation() {
    switch(type) {
      case 'extraBig':
        return pieHelper.padding_84;
      case 'big':
        return pieHelper.padding_70;
      case 'mediumBig':
        return pieHelper.padding_60;
      case 'medium':
        return pieHelper.padding_40;
      case 'small':
        return pieHelper.padding_25;
      case 'extraSmall':
        return pieHelper.padding_20;
    }
  }

  function getInnerRadius() {
    switch(type) {
      case 'extraBig':
        return 75;
      case 'big':
        return 64;
      case 'mediumBig':
        return 58;
      case 'medium':
        return 35;
      case 'small':
        return 20;
      case 'extraSmall':
        return 17;
    }
  }

  function getOuterRadius() {
    switch(type) {
      case 'extraBig':
        return 82;
      case 'big':
        return 69;
      case 'mediumBig':
        return 53;
      case 'medium':
        return 38;
      case 'small':
        return 22;
      case 'extraSmall':
        return 19;
    }
  }

  function getFontSize() {
    switch(type) {
      case 'extraBig':
        return '80px';
      case 'big':
        return '80px';
      case 'mediumBig':
        return '60px';
      case 'medium':
        return '36px';
      case 'small':
        return '18px';
      case 'extraSmall':
        return '18px';
    }
  }

  function getYPosition() {
    switch (type) {
      case 'extraBig':
        if(showSleepIQScoreLabel()) {
          return 45;
        }
        return 30;
      case 'big':
        if(showSleepIQScoreLabel()) {
          if(isEditedSession()) return 38;
          return 45;
        }
        return 30;
      case 'mediumBig':
        return 25;
      case 'medium':
        return 15;
      case 'small':
        return 7;
      case 'extraSmall':
        return 6;
    }
  }

  function appendText(svg, translation, fontSize, fontWeight, yPosition, text) {
    return svg.append('text')
      .attr('text-anchor', 'middle')
      .attr(
        'transform',
        `translate(${translation},${translation})`
      )
      .attr("font-family", `Avenir_${fontWeight}`)
      .attr('font-size', fontSize)
      .attr('font-weight', fontWeight)
      .attr('y', yPosition)
      .style('fill', colorHelper['text'])
      .text(text);
  }

  function showSleepIQScoreLabel() {
    return (type === 'extraBig' || type === 'big') && !isReport && !sleepTrendsType;
  }

  function isEditedSession() {
    if(session) return (session.startDate !== session.originalStartDate) || (session.endDate !== session.originalEndDate);
    return false;
  }
})();