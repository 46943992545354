(function () {

  class SleepHealthChart {
    id = null;
    type = null;
    data = null;
    svg = null;
    axis = null;
    tooltip = null;
    isEdited = null;

    constructor(id, sleepHealthData, type, isEdited) {
      this.id = id;
      this.data = sleepHealthData;
      this.type = type;
      this.isEdited = isEdited;
    }

    get isDuration() {
      return this.type === 'duration';
    }

    findByIndexFun(d) {
      return (date) => date.sessionDate === d.sessionDate;
    }

    getSleepHealthDataByIndex() {
      return this.data.findIndex(this.findByIndexFun);
    }

    setSvg(svg) {
      this.svg = svg;
    }

    setAxis(axis) {
      this.axis = axis;
    }

    setTooltip(tooltip) {
      this.tooltip = tooltip;
    }

    positionTooltipBody(day) {
      return this.axis.x(moment(this.tooltip.day.sessionDate)) + paddings.padding_32 + checkTooltipPositionForSmallScreens(this.getSleepHealthDataByIndex());
    }

    positionTooltipText(day, property) {
      return paddings.padding_32 + centerTooltipTextHorizontally(day, property, this.tooltip.tooltipWidth) + checkTooltipPositionForSmallScreens(this.getSleepHealthDataByIndex());

    }

    getRectColor(day) {
      return day.data[this.type].goalMet ?
        this.svg.colors.rect :
        this.svg.colors.white;
    }

    calculateAxis(axis, value) {
      switch (axis) {
        case 0: // x axis
          return this.axis.x(value);
        case 1: // y axis
          return this.axis.y(value);
        default:
          return 0;
      }
    }
  }
  class SleepHealthSvg {
    svg;
    width;
    height;
    containerWidth = 330;
    containerHeight = 200;
    colors;

    constructor(svg, width, height, color) {
      this.svg = svg;
      this.width = width;
      this.height = height;
      this.containerWidth = width + margin.left + margin.right;
      this.containerHeight = height + margin.top + margin.bottom;
      this.colors = new SleepHealthColors(color);
    }
  }
  class SleepHealthAxis {
    yAxisWidth = 25;
    yAxisHeight = 200;
    xAxisWidth = 330;
    xAxisHeight = 44;
    maxYAxisValue = null;
    x = null;
    y = null;
    xTickValues = null;
    yTickValues = null;

    constructor(maxYAxisValue, x, y, xTickValues, yTickValues) {
      this.maxYAxisValue = maxYAxisValue;
      this.x = x;
      this.y = y;
      this.yTickValues = yTickValues;
      this.xTickValues = xTickValues;
    }
  }
  class SleepHealthColors {
    white = '#F2F5F7';
    background = '#001D34';
    tooltip = '#335978';
    editedLabel = '#99ABBD';
    rect = null;

    constructor(rectColor) {
      this.rect = rectColor;
    }
  }
  class SleepHealthTooltip {
    day = null;
    type = null;
    dayIndex = null;
    bigTooltipHeight = 63;
    smallTooltipHeight = 45;
    tooltipMargin = 16;

    constructor(day, type, index) {
      this.type = type;
      this.day = day;
      this.dayIndex = index;
    }

    get date() {
      return moment(this.day.sessionDate).format('MMM D');
    }

    get value() {
      return this.setValue();
    }

    get avgValue() {
      return this.setAvgValue();
    }

    get maxText() {
      const avgValueWidth = this.calculateTextWidth(this.avgValue);
      const valueWidth = this.calculateTextWidth(this.value);
      return avgValueWidth > valueWidth ? avgValueWidth : valueWidth;
    }

    get tooltipWidth() {
      return this.maxText + this.tooltipMargin + paddings.padding_12;
    }

    get tooltipHeight() {
      return this.hasSleepHealthData ? this.bigTooltipHeight : this.smallTooltipHeight;
    }

    get hasSleepHealthData() {
      return this.day.hasOwnProperty('data') && this.day.absenceCode !== -2;
    }

    calculateTextWidth(value) {
      return getTextWidth(value, '14');
    }

    setValue() {
      return this.day.data[this.type].value ? `${this.formatValue(this.day.data[this.type].value)}` : 'No data';
    }

    setAvgValue() {
      if (this.hasSleepHealthData) {
        return !sleepHealthChart.isDuration ? `Avg: ${this.formatValue(this.day.data[this.type].goal)}` : `Goal: ${this.formatValue(this.day.data[this.type].goal)}`;
      }
      return 0;
    }

    formatValue(value) {
      const time = moment.utc(value * 1000);
      let formattedTime = '';
      if (time.seconds() >= 30) {
        time.add('1', 'minute');
      }

      if (time.hours() > 0) {
        formattedTime += `${time.hours()}h`;
      }

      if (time.minutes() > 0) {
        if (formattedTime === '') {
          formattedTime += `${time.minutes()}m`;
        } else {
          formattedTime += ` ${time.minutes()}m`;
        }
      }
      return formattedTime;
    }
  }

  (margin = {
    top: 20,
    right: 32,
    bottom: 30,
    left: 32
  });

  var paddings = {
    padding_2: 2,
    padding_5: 5,
    padding_6: 6,
    padding_10: 10,
    padding_12: 12,
    padding_14: 14,
    padding_15: 15,
    padding_20: 20,
    padding_24: 24,
    padding_28: 28,
    padding_31: 31,
    padding_32: 32,
    padding_33: 33,
    padding_50: 50,
    padding_56: 56,
    padding_74: 74,
  };

  const seconds_in_an_hour = 3600;
  const durationRectColor = '#85F5FF';
  const efficiencyRectColor = '#6BFCAC';
  const mobileWidth = 360;

  // sleep health chart that is being drawn
  let sleepHealthChart = null;

  // gets hovered date
  var bisectDate = d3.bisector(function (d) {
    return moment(d.sessionDate);
  }).left;

  window.generateSHChart = function (id, sleepHealth, type, isEdited) {
    // create sleep health chart object
    sleepHealthChart = new SleepHealthChart(id, sleepHealth, type, isEdited);
    clearChart();
    drawChart();

    window.onresize = function (event) {
      clearChart();
      drawChart();
    };
  };

  // #region clear chart
  function clearChart() {
    d3.select(`#${sleepHealthChart.id}`).select('svg').remove();
    d3.select(`#${sleepHealthChart.id}`).select('.tooltip').remove();
  }

  // #endregion

  // #region draw chart
  function drawChart() {
    const data = sleepHealthChart.data;
    const type = sleepHealthChart.type;
    const containerWidth = d3.select('.sleep-health-chart-container').node().getBoundingClientRect().width;
    const containerHeight = d3.select('.sleep-health-chart-container').node().getBoundingClientRect().height;
    const chartColor = type === 'duration' ? durationRectColor : efficiencyRectColor;

    const svg = d3.select(`#${sleepHealthChart.id}`).append('svg')
      .attr('width', containerWidth)
      .attr('height', containerHeight + (sleepHealthChart.isEdited ? 16 : 0)) // 16 is height of the "Edited" label
      .attr('viewBox', `0  -${sleepHealthChart.isEdited ? 86 : 70} ${containerWidth} ${containerHeight + 70 + (sleepHealthChart.isEdited ? 16 : 0)}`)
      .attr('xmlns', 'http://www.w3.org/2000/svg')
      .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
      .attr('xmlns:svgjs', 'http://svgjs.com/svgjs');

    const chartWidth = containerWidth - margin.left - margin.right;
    const chartHeight = containerHeight - 70 - (sleepHealthChart.isEdited ? 16 : 0); // to leave space for the tooltip and edited label

    // get max value 
    // if there is no data for the day the value will be 0
    // extent returns min and max values
    const maxValue = d3.extent(data, (d) => d.hasOwnProperty('data') ? d.data[type].value : 0);

    // get max goal value 
    // goal is calculated as goal + threshold
    // otherwise is set to 0
    // extent returns min and max values
    const maxGoal = d3.extent(data, (d) => {
      if (d.hasOwnProperty('data')) {
        return d.data[type].hasOwnProperty('goal') ? d.data[type].goal + d.data[type].threshold : 0;
      }
      return 0;
    });

    // max value determined between max of a goal and value
    const yValues = [0, Math.max(maxValue[1], maxGoal[1])];

    const xValues = d3.extent(data, (d) => moment(d.sessionDate));

    // x and y axis tick values
    const xTickValues = data.map(it => moment(it.sessionDate));
    const maxYAxisValue = yValues[1];

    // on y-axis show only first, middle and last tick
    const yTickValues = [0, Math.ceil(maxYAxisValue / 2), maxYAxisValue];

    // scale x and y axis
    const x = d3.scaleTime().range([0, chartWidth]);
    x.domain([xValues[0], xValues[1]]);

    const y = d3.scaleLinear().range([chartHeight, 0]);
    y.domain([0, maxYAxisValue]);

    // create chart objects
    // svg and axis
    const chartSvg = new SleepHealthSvg(svg, chartWidth, chartHeight, chartColor);
    const chartAxis = new SleepHealthAxis(maxYAxisValue, x, y, xTickValues, yTickValues);
    sleepHealthChart.setSvg(chartSvg);
    sleepHealthChart.setAxis(chartAxis);
    // edited label
    if(sleepHealthChart.isEdited) {
      createEditedLabel(containerWidth);
    }
    // create x-axis and y-axis
    createXAxis();

    createXAxisDate();

    createYAxis();

    // create goal indicator
    createGoalRect();

    // create goal line
    createGoalLine();

    // create chart bars
    createBars();

    createBarDiamondTip();

    createBarCircleTip();

    drawFocus();
  }
  // #endregion

  //#region Helper functions

  // #region edited label
  function createEditedLabel(containerWidth) {
    sleepHealthChart.svg.svg
      .append("text")
      .attr("x", containerWidth / 2 - paddings.padding_20) // 20 is half of the "Edited" label width
      .attr("y", -paddings.padding_33)
      .text("Edited")
      .style('fill', sleepHealthChart.svg.colors.editedLabel)
      .style('font-family', 'Avenir_400')
      .style('font-size', '14px')
      .style('line-height', '18px')
      .style('font-weight', 400)
      .exit().remove();
  }
  // #endregion

  // #region x-axis
  function createXAxis() {
    sleepHealthChart.svg.svg
      .append('g')
      .attr('id', `x-axis-${sleepHealthChart.type}`)
      .attr('class', 'axis')
      .attr(
        'transform',
        `translate(${paddings.padding_32},${sleepHealthChart.svg.height + margin.bottom})`
      )
      .call(
        d3
          .axisBottom(sleepHealthChart.axis.x)
          .tickValues(sleepHealthChart.axis.xTickValues)
          .tickFormat((d) => d3.timeFormat("%a")(d).slice(0, 2)) // to take only first two letters of a day Sat -> Sa
          .tickSize(0)
      )
      .call(g => g.select('.domain').remove())
      .exit().remove();
  }
  // #endregion

  // #region x-axis date
  function createXAxisDate() {
    sleepHealthChart.svg.svg
      .append('g')
      .attr('id', `x-axis-date-${sleepHealthChart.type}`)
      .attr('class', 'axis')
      .attr(
        'transform',
        `translate(${paddings.padding_32},${sleepHealthChart.svg.height + margin.bottom + margin.top})`
      )
      .call(
        d3
          .axisBottom(sleepHealthChart.axis.x)
          .tickValues(sleepHealthChart.axis.xTickValues)
          .tickFormat((d, i) => {
            if (i === 0 || i === sleepHealthChart.data.length - 1) {
              return d.format('M/D');
            }
          })
          .tickSize(0)
      )
      .call(g => g.select('.domain').remove())
      .exit().remove();
  }
  // #endregion

  // #region y-axis
  function createYAxis() {
    sleepHealthChart.svg.svg
      .append('g')
      .attr('id', `y-axis-${sleepHealthChart.type}`)
      .attr('transform', `translate(${0},${margin.top})`)
      .attr('class', 'axis')
      .call(
        d3
          .axisLeft(sleepHealthChart.axis.y)
          .tickValues(sleepHealthChart.axis.yTickValues)
          .tickFormat((d, i) => {
            const time = `${Math.ceil(d / seconds_in_an_hour)}`;
            return i === 0 ? time : `${time}h`;
          })
          .tickPadding(paddings.padding_5)
          .tickSize(0)
      )
      .call(g => g.select('.domain').remove())
      .exit().remove();

    //in order to align the ticks according to sleep health design
    //alignment of the top label shouldn’t be on the bottom of the y-axis, it should be on top of the label. 
    // bottom label - bottom alignment
    // middle label - center alignment
    // top label - top alignment
    d3.selectAll(`.y-axis-${sleepHealthChart.type} .tick`).each(function (d, i) {
      const tick = d3.select(this).attr('transform');
      const transformValue = tick.substr(tick.lastIndexOf(',') + 1, tick.lastIndexOf(')'));
      const value = parseInt(transformValue.replace(')', ''));
      d3.select(this)
        .attr("transform", `translate(0, ${i <= 1 ? value - 5 : value + 5})`);
    });
  }
  // #endregion

  // #region create goal rect
  function createGoalRect() {
    const rectData = transformDataForGoal();
    sleepHealthChart.svg.svg
      .append('g')
      .attr('id', `goal-rect-${sleepHealthChart.type}`)
      .selectAll(`goal-rect-${sleepHealthChart.type}`)
      .data(rectData.height)
      .enter()
      .append("rect")
      .attr('transform', `translate(${0},${margin.top})`)
      .attr('x', (d, i) => rectData.x[i])
      .attr('y', (d, i) => rectData.y[i])
      .attr('width', (d, i) => rectData.width[i])
      .attr('height', (d, i) => rectData.height[i])
      .attr('rx', 2)
      .style('fill', sleepHealthChart.svg.colors.rect)
      .style('opacity', '0.2')
      .exit().remove();
  }
  // #endregion

  // #region bars
  function createBars() {
    sleepHealthChart.svg.svg
      .append('g')
      .selectAll('rect')
      .data(sleepHealthChart.data)
      .enter()
      .append('rect')
      .attr('transform', `translate(${paddings.padding_32},${margin.top + paddings.padding_12})`)
      .filter((d) => d.hasOwnProperty('data') && d.absenceCode !== -2)
      .attr('x', (d) => sleepHealthChart.calculateAxis(0, moment(d.sessionDate)))
      .attr('y', (d) => calculateRectYValue(d))
      .attr('rx', 1)
      .attr('width', '2px')
      .attr('height', (d) => calculateBarHeight(d))
      .attr('fill', (d) => sleepHealthChart.getRectColor(d))
      .exit().remove();
  }
  // #endregion

  // #region diamond bar tip
  function createBarDiamondTip() {
    sleepHealthChart.svg.svg
      .append('g')
      .attr('id', `bar-diamond-tip-${sleepHealthChart.type}`)
      .selectAll(`bar-diamond-tip-${sleepHealthChart.type}`)
      .data(sleepHealthChart.data)
      .enter()
      .filter((d) => d.hasOwnProperty('data') && d.absenceCode !== -2 && !d.data[sleepHealthChart.type].goalMet)
      .append('rect')
      // get center point 
      .attr('transform', (d) => {
        return `translate(${paddings.padding_31}, ${paddings.padding_24})rotate(45 ${sleepHealthChart.calculateAxis(0, moment(d.sessionDate)) + paddings.padding_6} ${calculateRectCenter(d)})`;
      })
      .attr('x', (d) => sleepHealthChart.calculateAxis(0, moment(d.sessionDate)))
      .attr('y', (d) => calculateRectYValue(d))
      .attr('width', '12px')
      .attr('height', '12px')
      .attr('fill', sleepHealthChart.svg.colors.white);
  }
  // #endregion

  // #region bar circle tip
  function createBarCircleTip() {
    sleepHealthChart.svg.svg
      .append('g')
      .attr('id', `bar-circle-tip-${sleepHealthChart.type}`)
      .selectAll(`bar-circle-tip-${sleepHealthChart.type}`)
      .data(sleepHealthChart.data)
      .enter()
      .filter((d) => d.hasOwnProperty('data') && d.absenceCode !== -2 && d.data[sleepHealthChart.type].goalMet)
      .append('circle')
      .attr('transform', `translate(${paddings.padding_33}, ${paddings.padding_28})`)
      .attr('cx', (d) => sleepHealthChart.calculateAxis(0, moment(d.sessionDate)))
      .attr('cy', (d) => calculateRectYValue(d))
      .attr('r', 6)
      .attr('fill', sleepHealthChart.svg.colors.rect);
  }
  // #endregion

  // #region goal line
  function createGoalLine() {
    sleepHealthChart.svg.svg
      .append('g')
      .selectAll('line')
      .data(sleepHealthChart.data)
      .enter()
      .append('line')
      .filter((d) => d.hasOwnProperty('data') && d.data[sleepHealthChart.type].hasOwnProperty('goal') && d.data[sleepHealthChart.type].goal)
      .attr('y1', (d) => sleepHealthChart.calculateAxis(1, d.data[sleepHealthChart.type].goal))
      .attr('x1', (d) => sleepHealthChart.calculateAxis(0, moment(d.sessionDate)))
      .attr('y2', (d) => sleepHealthChart.calculateAxis(1, d.data[sleepHealthChart.type].goal))
      .attr('x2', (d, i) => {
        const dayPlusOne = moment(d.sessionDate).add(1, 'day');
        if (i === sleepHealthChart.data.length - 1) {
          return sleepHealthChart.calculateAxis(0, dayPlusOne) - paddings.padding_10;
        }
        return sleepHealthChart.calculateAxis(0, dayPlusOne);
      })
      .attr('transform', `translate(${0},${margin.top})`)
      .attr('fill', 'none')
      .attr('stroke', sleepHealthChart.svg.colors.rect)
      .style('opacity', '0.3')
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', 3)
      .attr('stroke-linecap', 'round')
      .exit().remove();
  }
  // #endregion

  //#region tooltip

  //#region  draw focus
  function drawFocus() {
    //Create focus object;
    let focus = sleepHealthChart.svg.svg.append('g').attr('class', 'focus');

    // create goal met tooltip tip
    createTooltipTip(focus, true);

    // create goal not met tooltip tip
    createTooltipTip(focus, false);

    // add background rectangle behind the text tooltip
    focus.append('rect')
      .attr('class', 'tooltip-body')
      .attr('rx', 4)
      .attr('ry', 4)
      .style('display', 'none')
      .exit().remove();

    // append vertical line below the circle
    focus.append('rect')
      .attr('class', 'tooltip-line')
      .attr('width', 2)
      .attr('fill', sleepHealthChart.svg.colors.white)
      .attr('rx', 1)
      .exit().remove();

    // add the first line of text annotation for tooltip
    focus.append('text')
      .attr('class', 'date-text')
      .attr('dy', '-42')
      .style('fill', 'white')
      .style('font-family', 'Avenir_400')
      .style('font-size', '14px')
      .style('line-height', '18px')
      .style('font-weight', 400);

    // add the second line of text annotation for tooltip
    focus.append('text')
      .attr('class', 'value-text')
      .attr('dy', '-69')
      .style('fill', 'white')
      .style('font-family', 'Avenir_900')
      .style('font-size', '14px')
      .style('line-height', '18px')
      .style('font-weight', 900);

    // add the third line of text annotation for tooltip
    focus.append('text')
      .attr('class', 'avg-text')
      .attr('dy', '-69')
      .style('fill', 'white')
      .style('font-family', 'Avenir_400')
      .style('font-size', '14px')
      .style('line-height', '18px')
      .style('font-weight', 400);

    // create an overlay rectangle to draw the above objects on top of
    sleepHealthChart.svg.svg.append('rect')
      .attr('class', 'overlay')
      .attr('width', sleepHealthChart.svg.containerWidth)
      .attr('height', sleepHealthChart.svg.containerHeight)
      .attr(
        'transform',
        `translate(${paddings.padding_6},${0})`
      )
      .attr('x', 2)
      .on('mouseover', () => focus.style('display', null))
      .on('mouseout', () => focus.style('display', 'none'))
      .on('mousemove', () => tipMove(focus));

    // make the overlay rectangle transparent,
    // so it only serves the purpose of detecting mouse events
    d3.selectAll('.overlay')
      .style('fill', 'none')
      .style('pointer-events', 'all');
  }
  //#endregion

  //#region get hovered date
  // below code finds the date by bisecting and stores the x and y coordinate as variables
  function getHoveredDate() {
    let x0 = sleepHealthChart.axis.x.invert(d3.pointer(event)[0]);
    let i = bisectDate(sleepHealthChart.data, x0, 1);
    let d0 = sleepHealthChart.data[i - 1];
    let d1 = sleepHealthChart.data[i - 1];
    let d = x0 - d0.sessionDate > d1.sessionDate - x0 ? d1 : d0;
    return d;
  }
  //#endregion

  //#region tip move
  // function that adds tooltip on hover
  function tipMove(focus) {

    d = getHoveredDate();
    // get the index of the hovered date
    const index = sleepHealthChart.getSleepHealthDataByIndex();

    // convert date to moment date and calculate x value
    const dayDate = moment(d.sessionDate);
    const dayXAxis = sleepHealthChart.calculateAxis(0, dayDate);

    // get day value
    const dayValue = d.data[sleepHealthChart.type].value;

    // create a tooltip object
    const tooltip = new SleepHealthTooltip(d, sleepHealthChart.type, index);
    sleepHealthChart.setTooltip(tooltip);

    // reset focus elements
    focus.selectAll('.tooltip-tip-goal-met')
      .style('display', 'none');

    focus.selectAll('.tooltip-tip-goal-not-met')
      .style('display', 'none');

    focus.select('.tooltip-line')
      .style('display', 'none');

    focus.select('.avg-text')
      .style('display', 'none');


    addTooltipText(focus);
    // if the user hovered over data day
    if (sleepHealthChart.tooltip.hasSleepHealthData) {

      // if the user has met its goal
      if (d.data[sleepHealthChart.type].goalMet) {

        focus.selectAll('.tooltip-tip-goal-met')
          .style('display', 'block')
          .attr('transform', `translate(${dayXAxis + paddings.padding_33}, ${sleepHealthChart.calculateAxis(1, dayValue) + paddings.padding_28})`);
        focus.selectAll('#outer-circle')
          .attr('stroke', sleepHealthChart.svg.colors.rect);
        focus.selectAll('#inner-circle')
          .style('fill', sleepHealthChart.getRectColor(d));
      } else {
        // if the user did not meet its goal
        focus.selectAll('.tooltip-tip-goal-not-met')
          .style('display', 'block')
          .attr('transform', `translate(${dayXAxis + paddings.padding_33}, ${sleepHealthChart.calculateAxis(1, dayValue) + paddings.padding_28})`);
        focus.selectAll('#outer-circle')
          .attr('stroke', sleepHealthChart.svg.colors.white);
        focus.selectAll('#inner-rect')
          .style('fill', sleepHealthChart.svg.colors.white)
          .attr('transform', `translate(${paddings.padding_31}, ${paddings.padding_24})rotate(45 ${dayXAxis + paddings.padding_6} ${calculateRectCenter(d)})`)
          .attr('x', dayXAxis)
          .attr('y', calculateRectYValue(d));
      }

    } else {
      // if the user hovered over no data day
      focus.select('.tooltip-line')
        .style('display', 'block');

      //place tooltip line
      focus.select('.tooltip-line')
        .attr('transform', `translate(${dayXAxis + paddings.padding_32}, ${sleepHealthChart.svg.height - margin.bottom})`)
        .attr('height', 24);
    }

  }
  //#endregion

  //#region add tooltip text
  // adds tooltip text (date, value and avg value)
  function addTooltipText(focus) {

    focus.select('.tooltip-body')
      .attr('x', sleepHealthChart.positionTooltipBody(sleepHealthChart.tooltip.day.sessionDate))
      .attr('transform', `translate(${-sleepHealthChart.tooltip.tooltipWidth / 2}, ${-sleepHealthChart.tooltip.tooltipHeight + paddings.padding_12})`)
      .style('fill', sleepHealthChart.svg.colors.tooltip)
      .style('display', 'block')
      .attr('width', `${sleepHealthChart.tooltip.tooltipWidth}px`)
      .attr('height', `${sleepHealthChart.tooltip.tooltipHeight}px`);

    focus.select('.date-text').text(sleepHealthChart.tooltip.date)
      .attr('x', sleepHealthChart.positionTooltipText(sleepHealthChart.tooltip.day.sessionDate, sleepHealthChart.tooltip.date))
      .attr('transform', `translate(${0}, ${sleepHealthChart.tooltip.hasSleepHealthData ? paddings.padding_10 : paddings.padding_28})`);

    focus.select('.value-text').text(sleepHealthChart.tooltip.value)
      .attr('x', sleepHealthChart.positionTooltipText(sleepHealthChart.tooltip.day.sessionDate, sleepHealthChart.tooltip.value) - 2)
      .attr('transform', `translate(${0}, ${sleepHealthChart.tooltip.hasSleepHealthData ? paddings.padding_56 : paddings.padding_74})`);

    // this element is conditions since for the no-data days we only show date and value ('No data')
    if (sleepHealthChart.tooltip.hasSleepHealthData) {
      focus.select('.avg-text').text(sleepHealthChart.tooltip.avgValue)
        .style('display', 'block')
        .attr('x', sleepHealthChart.positionTooltipText(sleepHealthChart.tooltip.day.sessionDate, sleepHealthChart.tooltip.avgValue))
        .attr('transform', `translate(${0}, ${paddings.padding_74})`);
    }
  }
  //#endregion

  //#region tooltip goal tip
  function createTooltipTip(focus, isGoalMet) {
    // create outer circle this is common for both diamond and circle
    focus.append('circle')
      .attr('class', isGoalMet ? 'tooltip-tip-goal-met' : 'tooltip-tip-goal-not-met')
      .attr('id', 'outer-circle')
      .attr('fill', sleepHealthChart.svg.colors.background)
      .attr('stroke', 'black')
      .attr('stroke-width', 2)
      .attr('r', 12)
      .style('display', 'none');

    // based on the condition add diamond or circle as an inner element
    if (isGoalMet) {
      focus.append('circle')
        .attr('class', 'tooltip-tip-goal-met')
        .attr('id', 'inner-circle')
        .attr('stroke', sleepHealthChart.svg.colors.background)
        .attr('stroke-width', 2)
        .attr('r', 7)
        .style('fill', 'transparent')
        .style('display', 'none');
    } else {
      focus.append('rect')
        .attr('class', 'tooltip-tip-goal-not-met')
        .attr('id', 'inner-rect')
        .attr('stroke', sleepHealthChart.svg.colors.background)
        .attr('stroke-width', 2)
        .attr('width', '12px')
        .attr('height', '12px')
        .style('fill', 'transparent')
        .style('display', 'none');
    }
  }
  //#endregion

  //#region center tooltip horizontally
  // Tool tip positions
  function centerTooltipTextHorizontally(date, value, tooltipWidth) {
    // first of all - place the text at the beginning of tooltip body
    const x_start = Math.floor(sleepHealthChart.axis.x(moment(date)) - tooltipWidth / 2);
    // move the text to the center of tooltip body
    // count the remaining empty space on the tooltip and divide it by 2   
    const x_center = Math.floor(tooltipWidth - getTextWidth(value, '14')) / 2;
    return x_start + x_center - paddings.padding_2;
  }
  //#endregion

  //#region get text width
  function getTextWidth(text, font) {
    textElement = document.createElement('span');
    document.body.appendChild(textElement);

    textElement.style.font = 'Avenir_400';
    textElement.style.fontSize = font + 'px';
    textElement.style.lineHeight = '18px';
    textElement.style.height = 'auto';
    textElement.style.width = 'auto';
    textElement.style.position = 'absolute';
    textElement.style.whiteSpace = 'no-wrap';
    textElement.style.color = 'transparent';
    textElement.innerHTML = text;
    const width = Math.ceil(textElement.clientWidth);

    document.body.removeChild(textElement);

    return width;
  }
  //#endregion

  //#region tooltip position on small screens
  function checkTooltipPositionForSmallScreens(dateIndex) {
    const windowWidth = self.innerWidth;
    if (dateIndex === 0 && windowWidth <= mobileWidth) {
      return 14;
    } else if (dateIndex === sleepHealthChart.data.length - 1 && windowWidth <= mobileWidth) {
      return -25;
    }
    return 0;
  }
  //#endregion

  //#endregion

  //#region transform data for drawing goal rect
  function transformDataForGoal() {
    const data = sleepHealthChart.data;
    const type = sleepHealthChart.type;
    const goalRectWidths = [];
    const goalRectHeight = [];
    const xValues = [sleepHealthChart.calculateAxis(0, moment(data[0].sessionDate))];
    const yValues = [calculateYValue(0)];
    rectWidth = 0;
    let goal = Object.prototype.hasOwnProperty.call(data[0], 'data') ? data[0].data[type].goal : 0;
    for (let i = 0; i < data.length; i++) {
      if (Object.prototype.hasOwnProperty.call(data[i], 'data') && goal != data[i].data[type].goal) {
        goalRectWidths.push(rectWidth);
        rectWidth = calculateRectWidth(data[i - 1].sessionDate, data[i].sessionDate);
        const rectHeight = calculateRectHeight(i - 1);
        goalRectHeight.push(rectHeight);
        xValues.push(sleepHealthChart.calculateAxis(0, moment(data[i].sessionDate)));
        yValues.push(calculateYValue(i));
        goal = Object.prototype.hasOwnProperty.call(data[i], 'data') ? data[i].data[type].goal : 0;
        i--;
      } else {
        rectWidth += calculateRectWidth(data[i].sessionDate, data[i].sessionDate);
      }
    }
    goalRectWidths.push(rectWidth);
    goalRectWidths[goalRectWidths.length - 1] -= paddings.padding_10;
    goalRectHeight.push(calculateRectHeight(data.length - 1));
    return { width: goalRectWidths, height: goalRectHeight, x: xValues, y: yValues };
  }
  //#endregion

  //#region calculate bar height
  // used for bar height calculations
  function calculateBarHeight(day) {
    return day.data[sleepHealthChart.type].value ?
      sleepHealthChart.svg.height - sleepHealthChart.calculateAxis(1, day.data[sleepHealthChart.type].value) - paddings.padding_6 :
      sleepHealthChart.calculateAxis(1, 0);
  }
  //#endregion

  //#region calculate rect height
  // used in transform data for the rect goal operation
  function calculateRectHeight(position) {
    const data = sleepHealthChart.data;
    const type = sleepHealthChart.type;
    if (sleepHealthChart.isDuration) {
      const threshold = Object.prototype.hasOwnProperty.call(data[position], 'data') ? data[position].data[type].threshold : 0;
      return sleepHealthChart.svg.height - sleepHealthChart.calculateAxis(1, 2 * threshold);
    }
    return sleepHealthChart.calculateAxis(1, data[position].data[type].goal - data[position].data[type].threshold);
  }
  //#endregion

  //#region calculate y value
  // used when calculating rect for goal
  function calculateYValue(position) {
    const data = sleepHealthChart.data;
    const type = sleepHealthChart.type;
    if (sleepHealthChart.isDuration) {
      return sleepHealthChart.calculateAxis(1, data[position].data[type].goal + data[position].data[type].threshold);
    }
    return sleepHealthChart.calculateAxis(1, sleepHealthChart.axis.maxYAxisValue);
  }
  //#endregion

  //#region calculate rect center
  function calculateRectCenter(day) {
    return day.data[sleepHealthChart.type].value ?
      sleepHealthChart.calculateAxis(1, day.data[sleepHealthChart.type].value) :
      sleepHealthChart.calculateAxis(1, 0) + paddings.padding_6;
  }
  //#endregion

  //#region calculate rect width
  // used when calculating rect for goal
  function calculateRectWidth(date1, date2) {
    return sleepHealthChart.calculateAxis(0, moment(date1).add(1, 'day')) - sleepHealthChart.calculateAxis(0, moment(date2));
  }
  //#endregion

  //#region calculate rect y value
  // used for calculating y value for the bar, bar tips and tooltips
  function calculateRectYValue(day) {
    return day.data[sleepHealthChart.type].value ? sleepHealthChart.calculateAxis(1, day.data[sleepHealthChart.type].value) : sleepHealthChart.calculateAxis(1, 0);
  }
  //#endregion

  //#endregion


})();
