(function() {
  class BiosignalsHistoryChart {
    id = null;
    chartType = null;
    data = null;
    edgeCaseAtTheBeginning = null;
    edgeCaseAtTheEnd = null;
    hasCircles = null;
    svg = null;
    width = null;
    height = 112;
    axis = null;
    tooltip = null;

    constructor(id, data) {
      this.id = id;
      this.data = data;
      this.chartType = this.type;
      this.edgeCaseAtTheEnd = this.checkEdgeCaseAtTheEnd();
      this.hasCircles = this.checkChartType('7') || this.checkChartType('week');
      this.width = d3.select('.chart-container').node().getBoundingClientRect().width;
    }

    // getters
    get type() {
      let idArray = this.id.split('-');
      // history chart
      if(idArray.left === 2) {
        return idArray[0];
      }
      // biosignals chart
      return idArray[idArray.length - 2];
    }

    get isHistory() {
      return this.id.includes('history');
    }

    // setters
    setEdgeCaseAtBeginning(isEdgeCase) {
      this.edgeCaseAtTheBeginning = isEdgeCase;
    }

    setSvg(svg) {
      this.svg = svg;
    }

    setAxis(axis) {
      this.axis = axis;
    }

    setTooltip(tooltip) {
      this.tooltip = tooltip;
    }

    formatChartId() {
      let idArray = this.id.split('-');
      // history chart
      if(idArray.length === 2) {
        // remove week/month/year part of the id
        return idArray.slice(1, 2).join('');
      }
      // biosignals chart
      // remove 7-days or 30-days parts of ides
      return idArray.slice(0, -2).join('-');
    }

    checkChartType(type) {
      return this.chartType === type;
    }

    // Max value of the data
    findMaxValue() {
      return d3.max(this.data, d => +d.value )
    }

    // Data edge cases
    checkEdgeCaseAtBeginning(index) {
      return index === 0 && !this.data[1].value;
    }

    checkEdgeCaseAtTheEnd() {
      const dataLength = this.data.length;
      // [..., null, value]
      return this.data[dataLength - 1].value && !this.data[dataLength - 2].value;
    }

    chackEdgeCasesForNoData(i) {
      return (
        // [..., null, value, null, ...]
        (i > 0 && i + 1 < this.data.length && this.data[i - 1].value === 0 && this.data[i + 1].value === 0) ||
        // [value, null, ...]
        (i === 0 && this.data[i + 1].value === 0) || 
        // [..., null, value]
        (i === this.data.length - 1 && this.data[i - 1].value === 0)
      );
    }

    checkJanuaryEdgeCase() {
      // [value, null, ...] in year chart
      const month = this.tooltip.day.date.split('-')[1]; // date is in yyyy/mm/dd format
      return this.checkChartType('year') && month === '01' && this.tooltip.day.value;
    }

    // Position of the number in the circle for 7-days charts
    calculateNumberPosition(value) {
      const numberOfDigits = value.toString().length;
      switch(numberOfDigits) {
        case 1:
          return 3;
        case 2:
          return 7;
        case 3:
          return 11;
      }
    }
  }

  class BiosignalsHistorySvg {
    svg = null;

    constructor(svg) {
      this.svg = svg;
    }
  }

  class BiosignalsHistoryAxis {
    x = null;
    y = null;

    constructor(x, y) {
      this.x = x;
      this.y = y;
    }

    createTimelineWithDays(svg, height) {
      svg.append('g')
        .style('font-size', '14px')
        .attr('transform', `translate(0,${height + 16})`)
        .call(d3.axisBottom(this.x).ticks(7).tickSize(0).tickFormat(d => moment(d).format('dd')));
    }

    createTimelineWithFirstAndLastDate(svg, height) {
      svg.append('g')
      .style('font-size', '14px')
      .attr('transform', `translate(0,${height + 40})`)
      .call(d3.axisBottom(this.x).ticks(7).tickSize(0).tickFormat((d, index) => { 
        if(index === 0 || index === 6) {
          return `${moment(d).format('M')}/${moment(d).format('D')}`; 
        }
      }));
    }

    createTimelineWithDatesEvery7Days(svg, data, height) {
      svg.append('g')
        .style('font-size', '14px')
        .attr('transform', `translate(0,${height + 16})`)
        .call(d3.axisBottom(this.x).ticks(30).tickSize(0).tickFormat((d, index) => { 
          if(index === 0 || index === data.length - 1 || (index%7 === 0 && data.length - index) > 3) {
            const d = data[index].date;
            return `${moment(d).format('M')}/${moment(d).format('D')}`; 
          }
        }));
    }

    createTimelineWithMonths(svg, height) {
      svg.append('g')
        .style('font-size', '14px')
        .attr('transform', `translate(0,${height + 20})`)
        .call(d3.axisBottom(this.x).ticks(12).tickSize(0).tickFormat((d, index) => { 
          if(index%2 === 0) {
            return `${moment(d).format('MMM')}`; 
          }
        }));
    }

    addValuesOnYAxis(svg) {
      svg.append("g")
        .attr("transform", "translate(-2, 0)")
        .call(d3.axisLeft(this.y).ticks(3).tickSize(0).tickPadding(8));
    }

    createGreenLines(biosignalsHistoryChart) {
      const self = this; // this variable will allow us to use class attributes inside the anonymous function 
      biosignalsHistoryChart.svg.append("g").selectAll("line.line").data(this.y.ticks(3)).enter()
        .append("line")
        .attr('class', 'horizontal-line grid tick')
        .attr('x1', 0)
        .attr('x2', this.calculateEndOfGreenLines(biosignalsHistoryChart))
        .attr('y1', function(d){ return self.y(d); })
        .attr('y2', function(d){  return self.y(d); })
    }

    calculateEndOfGreenLines(biosignalsHistoryChart) {
      const width = biosignalsHistoryChart.width - margin.right - margin.left;
      const isSmallScreen = window.innerWidth <= 360;
      if(biosignalsHistoryChart.edgeCaseAtTheEnd) {
        if(biosignalsHistoryChart.checkChartType('year')) return width + 10;
        return width + 12;
      }
      if(biosignalsHistoryChart.checkChartType('month')) return width + 17; 

      return width;
    }

  }

  class BiosignalsHistoryColors {
    colorHelper = {
      'breath-rate': '#85F5FF',
      'heart-rate': '#F84B20',
      'heart-rate-variability': '#6BFCAC',
      'history': '#6BFCAC',
      'dark-background': '#001D34',
      'white': '#F2F5F7',
      'tooltip-text': '#E6EBED',
      'tooltip-body': '#335978'
    };
    
    getColor(colorId) {
      return this.colorHelper[colorId];
    }
  }

  class BiosignalsHistoryTooltip {
    tooltipTextHelper = {
      'breath-rate': 'BrPM',
      'heart-rate': 'BPM',
      'heart-rate-variability': 'HRV',
      'sleep-number': 'Sleep Number',
      'setting': 'setting',
      'sleepiq': 'SleepIQ',
      'score': 'score'
    };
    tooltipHeight = null;
    tooltipWidth = null;
    tooltipMargin = 24; 
    tooltipPadding = 8;
    tooltipTextLength = null;
    day = null;
    dayIndex = null;
    chartType = null;
    focus = null;
    dateText = null;
    valueText = null;
    sleepNumberSettingText = null;

    constructor(focus, chartType) {
      this.focus = focus;
      this.chartType = chartType;
      this.tooltipHeight = chartType === 'week' || chartType === 'month' ? 64 : 45;
    }

    // Setters
    setDay(day) {
      this.day = day;
    }

    setDayIndex(dayIndex) {
      this.dayIndex = dayIndex;
    }

    setTooltipText(isHistory, formattedId) {
      this.dateText = this.chartType === 'year' ? moment(this.day.date).format('MMM') : moment(this.day.date).format('MMM D');
      
      this.valueText = this.day.value 
        ? isHistory
          ? `${this.tooltipTextHelper['sleepiq']} ${this.tooltipTextHelper['score']} ${this.day.value}` 
          : `${this.day.value} ${this.tooltipTextHelper[formattedId]}` 
        : 'No data';

      this.sleepNumberSettingText = this.day.sleepNumber ? `${this.tooltipTextHelper['sleep-number']} ${this.tooltipTextHelper['setting']} ${this.day.sleepNumber}` : '';
    }

    setTooltipWidth() {
      this.tooltipTextLength = this.sleepNumberSettingText.length >= this.valueText.length 
                                ? this.getTextWidth(this.sleepNumberSettingText, 14) + 20 
                                : biosignalsHistoryChart.isHistory && this.valueText !== 'No data'
                                  ? this.getTextWidth(this.valueText, 14) + 20
                                  : this.getTextWidth(this.valueText, 14) + 5;
      this.tooltipWidth = this.tooltipTextLength + 2 * this.tooltipPadding;
    }

    // Creating tooltip elements
    createCircle(hasCircles, fillColor, strokeColor, radius) {
      this.focus.append('circle')
        .attr('class', 'tooltip-circle')
        .attr('fill', fillColor)
        .attr('stroke', strokeColor)
        .attr('stroke-width', hasCircles ? 2 : 0)
        .attr('r', radius)
        .style('display', 'none');
    }

    createVerticalLine(color) {
      this.focus.append('rect')
        .attr('class', 'tooltip-line')
        .attr('width', 2)
        .attr('fill', color)
        .exit().remove();
    }

    createBackgroundRectangle() {
      this.focus.append('rect')
        .attr('class', 'tooltip-body')
        .attr('rx', 4)
        .attr('ry', 4)
        .style('display', 'none')
        .exit().remove();
    }

    createText(textClass, textColor, textWeight) {
      this.focus.append('text')
        .attr('class', textClass)
        .style('fill', textColor)
        .style('font-family', `Avenir_${textWeight}`)
        .style('font-size', '14px')
        .style('line-height', '18px')
        .style('font-weight', textWeight);
    }

    getTextWidth(text, font) {
      let textElement = document.createElement('span');
      document.body.appendChild(textElement);
  
      textElement.style.font = 'Avenir_500';
      textElement.style.fontSize = font;
      textElement.style.lineHeight = 18;
      textElement.style.height = 'auto';
      textElement.style.width = 'auto';
      textElement.style.position = 'absolute';
      textElement.style.whiteSpace = 'no-wrap';
      textElement.style.color = 'transparent';
      textElement.innerHTML = text;
      const width = Math.ceil(textElement.clientWidth);
  
      document.body.removeChild(textElement);
  
      return width;
    }

    // Tooltip positions
    translateTooltipLine(r, value, y) {
      if(!value) return 44;
      return y(value) + r;
    }

    calculateTooltipLineHeight(biosignalsHistoryChart, r, type, maxValue7Days, maxValue30Days) {
      let maxValue = 0;
      if(!biosignalsHistoryChart.isHistory) {
        if(type === '7') {
          maxValue = maxValue7Days;
        } else if(type === '30') {
          maxValue = maxValue30Days;
        }
      } else {
        maxValue = biosignalsHistoryChart.findMaxValue();
      }
    
      const value = biosignalsHistoryChart.tooltip.day.value;
      if(!value) return 24;
      let lineHeight = 0;
      biosignalsHistoryChart.checkChartType('month') || biosignalsHistoryChart.checkChartType('year')
        ? lineHeight = biosignalsHistoryChart.height - biosignalsHistoryChart.axis.y(value) - r 
        : lineHeight = value * (biosignalsHistoryChart.height / maxValue) - r;
      
      return lineHeight > 0 ? lineHeight : 0;
    }

    centerTooltipTextHorizontally(biosignalsHistoryChart, text) {
      // first of all - place the text at the beginning of tooltip body
      const xStart = Math.floor(biosignalsHistoryChart.axis.x(moment(biosignalsHistoryChart.tooltip.day.date)) - biosignalsHistoryChart.tooltip.tooltipWidth / 2);
      // move the text to the center of tooltip body
      // count the remaining empty space on the tooltip and divide it by 2   
      const xCenter = Math.floor((biosignalsHistoryChart.tooltip.tooltipWidth - biosignalsHistoryChart.tooltip.getTextWidth(text, 14) + (text.includes('setting') || text.includes('score') ? -12 : text === 'No data' ? 0 : 3)) / 2); 
      //NOTE: -10 and 3 are selected randomly, just to center text into the tooltip because we have different font on ®
      
      return xStart + xCenter;
    }

    placeTooltip(x, chartWidth, marginLeft, marginRight) {
      const currentDayXPosition = x(moment(this.day.date));
      // rest of the tooltip that will stay visible on right/left side when tooltip goes out of the chart scope
      const rightOffset = (chartWidth - marginRight) - currentDayXPosition;
      const leftOffset = (currentDayXPosition - this.tooltipWidth / 2);
      if(rightOffset < this.tooltipWidth / 2) {
        // move tooltip for the invisible part
        return -(this.tooltipWidth / 2 - rightOffset + 12);
      }
      if(leftOffset < -marginLeft) {
        // move tooltip for the invisible part and 32 for the y-axis
        return -(leftOffset + marginLeft - 12);
      }
      return 0;
    }
  
    checkTooltipPositionForSmallScreens(isHistory, chartDataLength) {
      const isSmallScreen = window.innerWidth <= 360;
      if(!isHistory) {
        if(this.dayIndex === 0 && isSmallScreen) {
          return 12;
        } else if(this.dayIndex === chartDataLength - 1 && isSmallScreen) {
          return -12;
        }
      }
      return 0;
    }
  
    // Tooltip text 
    createTrademarkInTooltipText(element, property) {
      // property keeps info about text in tooltip, it can be valueText or SleepNumberSettingText
      const text = this[property].split(' ');
      element.append('tspan')
        .text('®')
        .attr('dy', '-4')
        .attr('dx', '1')
        .style('font-size', '9px')
        .append('tspan').text(` ${text[text.length - 2]} ${text[text.length - 1]}`).attr('dy', '4').style('font-size', '14px');
        // text.length - 2 to get 'score' or 'setting' part, and text.length - 1 to get value or sleepNumber part
    }
  
  }

  // Set the dimensions and margins of the graph
  var margin = { top: 20, right: 36, bottom: 30, left: 36 };

  var colors = new BiosignalsHistoryColors();

  // Variables that will keep max values of the data in biosignals charts
  // Case: biosignals details page where we have 7-days and 30-days charts in the same page
  var maxValue7Days;
  var maxValue30Days;

  // Tooltip dimensions
  var tooltipHeight;

  // Varible that will select the closest date on the x axis when a user hovers over the chart
  var bisectDate = d3.bisector(function(d) {
    return moment(d.date);
  }).left;

  window.setBiosignalsHistoryData = function(chartId, data) {
    biosignalsHistoryChart = new BiosignalsHistoryChart(chartId, data);

    tooltipHeight = biosignalsHistoryChart.checkChartType('week') || biosignalsHistoryChart.checkChartType('month') ? 64 : 45;

    if(biosignalsHistoryChart.checkChartType('7')) {
      maxValue7Days = biosignalsHistoryChart.findMaxValue();
    }
    if(biosignalsHistoryChart.checkChartType('30')) {
      maxValue30Days = biosignalsHistoryChart.findMaxValue();
    }

    clearChart();
    drawChart();

    window.onresize = function (event) {
      biosignalsHistoryChart = new BiosignalsHistoryChart(chartId, data);
      clearChart();
      drawChart();
    };
  }

  function clearChart() {
    // Clear previous charts
    d3.selectAll(`#${biosignalsHistoryChart.id}`).select('svg').remove();
    d3.selectAll(`#${biosignalsHistoryChart.id}`).select('.tooltip-line').remove();
    d3.selectAll(`#${biosignalsHistoryChart.id}`).select('.tooltip-circle').remove();
  }

  function drawChart() {
    // Append the svg object to the body of the page
    var svg = d3.select(`#${biosignalsHistoryChart.id}`)
    .append('svg')
      .attr('width', biosignalsHistoryChart.width)
      .attr('height', biosignalsHistoryChart.height + 36 + tooltipHeight + margin.top + margin.bottom)
    .append('g')
      .attr('transform', `translate(${margin.left},${margin.top + tooltipHeight + 4})`);

    // Set svg to the chart
    biosignalsHistoryChart.setSvg(new BiosignalsHistorySvg(svg).svg);

    // X-axis
    const x = d3.scaleTime()
      .domain(d3.extent(biosignalsHistoryChart.data, d => moment(d.date)))
      .range([0, biosignalsHistoryChart.width - margin.left - margin.right])

    // Y-axis
    const y = d3.scaleLinear()
      .domain([0, biosignalsHistoryChart.checkChartType('month') || biosignalsHistoryChart.checkChartType('year') ? 100 : biosignalsHistoryChart.findMaxValue()])
      .range([biosignalsHistoryChart.height, 0]);

    // Set axis to the chart 
    biosignalsHistoryChart.setAxis(new BiosignalsHistoryAxis(x, y));

    if(biosignalsHistoryChart.hasCircles) {
      // Add timeline with days for 7-days chart or week-history
      biosignalsHistoryChart.axis.createTimelineWithDays(biosignalsHistoryChart.svg, biosignalsHistoryChart.height);
      // Add timeline with dates for 7-days chart or week-history
      biosignalsHistoryChart.axis.createTimelineWithFirstAndLastDate(biosignalsHistoryChart.svg, biosignalsHistoryChart.height);
    } else {
      if(biosignalsHistoryChart.checkChartType('year')) {
        // Add timeline with months for year-history chart
        biosignalsHistoryChart.axis.createTimelineWithMonths(biosignalsHistoryChart.svg, biosignalsHistoryChart.height);
      } else {
        // Add timeline with dates for 30-days chart or month-history
        biosignalsHistoryChart.axis.createTimelineWithDatesEvery7Days(biosignalsHistoryChart.svg, biosignalsHistoryChart.data, biosignalsHistoryChart.height);
      }
    }

    if(biosignalsHistoryChart.checkChartType('month') || biosignalsHistoryChart.checkChartType('year')) {
      // Add numbers on y-axis
      biosignalsHistoryChart.axis.addValuesOnYAxis(biosignalsHistoryChart.svg);

      // Add green lines 
      biosignalsHistoryChart.axis.createGreenLines(biosignalsHistoryChart);
    }

    // Gradient color 
    createGradientColor(svg);

    // Add the area
    biosignalsHistoryChart.svg.append('path')
      .datum(biosignalsHistoryChart.data)
      .attr('fill', 'url(#svg-gradient)')
      .attr('fill-opacity', .4)
      .attr('d', d3.area()
        .defined(d => d.value)
        .x(d => biosignalsHistoryChart.axis.x(moment(d.date)))
        .y0(biosignalsHistoryChart.height)
        .y1(d => biosignalsHistoryChart.axis.y(d.value))
      );
    
    // Edge cases for no data values
    biosignalsHistoryChart.svg
      .append('g')
      .selectAll('rect')
      .data(biosignalsHistoryChart.data)
      .enter()
      .append('rect')
      .attr('x', (d, i) => {
        if(biosignalsHistoryChart.chackEdgeCasesForNoData(i)) {
          // edgeCaseAtTheBeginning will be used to place tooltip line and circle
          if(biosignalsHistoryChart.checkEdgeCaseAtBeginning(i)) biosignalsHistoryChart.setEdgeCaseAtBeginning(true);
          
          if(!biosignalsHistoryChart.hasCircles) {
            return biosignalsHistoryChart.checkChartType('year')
                  // we need to sub 5 or 10 days to keep the edge case bar centered with the corresponding month on the x-axis
                  // for edge case at beginning sub 5, not 10 because we want to keep space between y-axis and the bar 
                  ? biosignalsHistoryChart.data[0].value && i === 0 
                    ? biosignalsHistoryChart.axis.x(moment(d.date)) - 5 
                    : biosignalsHistoryChart.axis.x(moment(d.date)) - 10
                  : biosignalsHistoryChart.axis.x(moment(d.date)) - 5; // biosignals 30-days chart and history month 
          }
          return biosignalsHistoryChart.axis.x(moment(d.date)) - 16; // biosignals 7-days and history week
        }
        return biosignalsHistoryChart.axis.x(0);
      })
      .style('stroke', colors.getColor(biosignalsHistoryChart.formatChartId()))
      .attr('fill', 'url(#svg-gradient)')
      .style('stroke-width', 2)
      .style('stroke-dashoffset', 0)
      .style('stroke-dasharray', biosignalsHistoryChart.checkChartType('year') ? '20 1000' : '9 1000')
      .filter(d => d.value)
      .attr('fill-opacity', .4)
      .attr('y', d => biosignalsHistoryChart.axis.y(d.value))
      .attr('width', function () {
        if(biosignalsHistoryChart.checkChartType('year')) return 20;
        if(!biosignalsHistoryChart.hasCircles) return 9;
        return 32;
      })
      // Max value of the data is on the top and all other values are scaled relative to that value
      // Height is calculated as real value multiplied by the scaling factor
      .attr('height', d => biosignalsHistoryChart.checkChartType('month') || biosignalsHistoryChart.checkChartType('year') 
                          ? biosignalsHistoryChart.height - biosignalsHistoryChart.axis.y(d.value) 
                          : d.value * (biosignalsHistoryChart.height / biosignalsHistoryChart.findMaxValue())) 
      .exit().remove();
    
    // Add the line
    biosignalsHistoryChart.svg.append('path')
      .datum(biosignalsHistoryChart.data)
      .attr('fill', 'none')
      .attr('stroke', colors.getColor(biosignalsHistoryChart.formatChartId()))
      .attr('stroke-width', 2)
      .attr('d', d3.line()
        .x(d => biosignalsHistoryChart.axis.x(moment(d.date)))
        .y(d => biosignalsHistoryChart.axis.y(d.value))
        .defined(d => d.value)
      );

    // Add the circles
    var elemEnter = biosignalsHistoryChart.svg.selectAll('circles')
      .data(biosignalsHistoryChart.data.filter(d => d.value && biosignalsHistoryChart.hasCircles))
      .enter();

    elemEnter.append('circle')
      .attr('fill', colors.getColor('dark-background'))
      .attr('stroke', colors.getColor(biosignalsHistoryChart.formatChartId()))
      .attr('stroke-width', 2)
      .attr('cx', d => biosignalsHistoryChart.axis.x(moment(d.date)))
      .attr('cy', d => biosignalsHistoryChart.axis.y(d.value))
      .attr('r', 16);
    
    elemEnter.append('text')
      .attr('fill', colors.getColor('white'))
      .attr('class', 'circle-text')
      .attr('dx', d => biosignalsHistoryChart.axis.x(moment(d.date)) - biosignalsHistoryChart.calculateNumberPosition(d.value))
      .attr('dy', d => biosignalsHistoryChart.axis.y(d.value) + 4)
      .text(d => d.value);
    
    // Tooltip
    function drawFocus() {
      // Create focus object
      let focus = biosignalsHistoryChart.svg.append('g').attr('class', 'focus');
      // Create tooltip object
      let tooltip = new BiosignalsHistoryTooltip(focus, biosignalsHistoryChart.chartType);

      // Append dark circle around the number (only for 7-days charts)
      tooltip.createCircle(biosignalsHistoryChart.hasCircles, 'transparent', colors.getColor('dark-background'), biosignalsHistoryChart.hasCircles ? 18 : 0);
   
      // Append light circle on the line path
      // Need to keep radius info, because it will be used to translate tooltip line and calculate line height
      const r = biosignalsHistoryChart.hasCircles ? 20 : 4;
      tooltip.createCircle(
        biosignalsHistoryChart.hasCircles,
        biosignalsHistoryChart.hasCircles ? 'transparent' : colors.getColor('white'),
        colors.getColor('white'),
        r
      );

      // Append vertical line below the circle
      tooltip.createVerticalLine(colors.getColor('white'));

      // Add background rectangle behind the tooltip text
      tooltip.createBackgroundRectangle();

      // Add the first line of text annotation for tooltip
      tooltip.createText('date-text', colors.getColor('tooltip-text'), 400);

      // Add the second line of text annotation for tooltip
      tooltip.createText('value-text', colors.getColor('white'), 900);

      // Add the third line of text annotation for week and month history charts
      if(biosignalsHistoryChart.checkChartType('week') || biosignalsHistoryChart.checkChartType('month')) {
        tooltip.createText('sleep-number-setting', colors.getColor('white'), 900);
      }

      // Create an overlay rectangle to draw the above objects on top of
      biosignalsHistoryChart.svg.append('rect')
        .attr('class', 'overlay')
        .attr('width', biosignalsHistoryChart.width + 10)
        .attr('height', biosignalsHistoryChart.height + 100)
        .attr('x', 2)
        .on('mouseover', () => focus.style('display', null))
        .on('mouseout', () => focus.style('display', 'none'))
        .on('mousemove', () => tipMove(r));

      // Make the overlay rectangle transparent, so it only serves the purpose of detecting mouse events
      d3.selectAll('.overlay')
        .style('fill', 'none')
        .style('pointer-events', 'all');
      
      // Function that adds tooltip on hover
      function tipMove(r) {
        // Need to set chart axis again, because we have a case with two charts on the same screen (biosignals chart)
        biosignalsHistoryChart.setAxis(new BiosignalsHistoryAxis(x, y));
        // This variable will keep info about chart type
        // r === 20: type is biosignals 7-days or week, r === 4: type is biosignals 30-days, month or year
        // We need this to make difference between these charts when we create tooltip
        const type = r === 20 ? '7' : '30';

        // Below code finds the date by bisecting and stores the x and y coordinate as variables
        let x0 = x.invert(d3.pointer(event)[0]);
        let i = bisectDate(biosignalsHistoryChart.data, x0, 1);
        let d0 = biosignalsHistoryChart.data[i - 1];
        let d1 = biosignalsHistoryChart.data[i - 1];
        let d = x0 - d0.date > d1.date - x0 ? d1 : d0;

        tooltip.setDay(d);

        // Set values for tooltip text
        tooltip.setTooltipText(biosignalsHistoryChart.isHistory, biosignalsHistoryChart.formatChartId());

        // Set values for tooltip text length and tooltip width
        tooltip.setTooltipWidth();

        // Values for tooltip positions
        const hoveredDate = date => date.date === d.date;
        tooltip.setDayIndex(biosignalsHistoryChart.data.findIndex(hoveredDate));

        // Set tooltip to the chart
        biosignalsHistoryChart.setTooltip(tooltip);

        const tooltipPosition = biosignalsHistoryChart.tooltip.placeTooltip(biosignalsHistoryChart.axis.x, biosignalsHistoryChart.width, margin.left, margin.right);
        const smallScreenOffset = biosignalsHistoryChart.tooltip.checkTooltipPositionForSmallScreens(biosignalsHistoryChart.isHistory, biosignalsHistoryChart.data.length);

        // place tooltip body
        biosignalsHistoryChart.tooltip.focus.select('.tooltip-body')
          .attr('x', -(biosignalsHistoryChart.tooltip.tooltipWidth / 2.) + tooltipPosition + smallScreenOffset)
          .attr('width', biosignalsHistoryChart.tooltip.tooltipWidth)
          .attr('height', biosignalsHistoryChart.tooltip.day.value ? biosignalsHistoryChart.tooltip.tooltipHeight : '45')
          .attr('transform', `translate(${biosignalsHistoryChart.axis.x(moment(biosignalsHistoryChart.tooltip.day.date))}, ${-(biosignalsHistoryChart.tooltip.day.value ? biosignalsHistoryChart.tooltip.tooltipHeight : 45) - biosignalsHistoryChart.tooltip.tooltipMargin})`)
          .style('fill', colors.getColor('tooltip-body'))
          .style('display', 'block');

        // place tooltip text
        biosignalsHistoryChart.tooltip.focus.select('.date-text').text(biosignalsHistoryChart.tooltip.dateText)
          .attr('x', biosignalsHistoryChart.tooltip.centerTooltipTextHorizontally(biosignalsHistoryChart, biosignalsHistoryChart.tooltip.dateText) + tooltipPosition + smallScreenOffset)
          .attr('dy', biosignalsHistoryChart.tooltip.tooltipHeight === 64 && biosignalsHistoryChart.tooltip.day.value ? '-60' : '-40')
          .attr('transform', `translate(0, ${-10})`);

        let value = biosignalsHistoryChart.tooltip.focus.select('.value-text').text(biosignalsHistoryChart.tooltip.day.value ? biosignalsHistoryChart.isHistory ? biosignalsHistoryChart.tooltip.valueText.split(' ')[0] : `${biosignalsHistoryChart.tooltip.valueText}` : 'No data')
          .attr('x', biosignalsHistoryChart.tooltip.centerTooltipTextHorizontally(biosignalsHistoryChart, biosignalsHistoryChart.tooltip.valueText) + tooltipPosition + smallScreenOffset)
          .attr('dy', biosignalsHistoryChart.tooltip.tooltipHeight === 64 && biosignalsHistoryChart.tooltip.day.value ? '-87' : '-67')
          .attr('transform', `translate(0, ${35})`);
        
          if(biosignalsHistoryChart.tooltip.day.value && biosignalsHistoryChart.isHistory) {
            biosignalsHistoryChart.tooltip.createTrademarkInTooltipText(value, 'valueText');
          }

        let setting = biosignalsHistoryChart.tooltip.focus.select('.sleep-number-setting').text(biosignalsHistoryChart.tooltip.day.sleepNumber ? biosignalsHistoryChart.tooltip.sleepNumberSettingText.split(' ').slice(0, 2).join(' ') : '')
          .attr('x', biosignalsHistoryChart.tooltip.centerTooltipTextHorizontally(biosignalsHistoryChart, biosignalsHistoryChart.tooltip.sleepNumberSettingText) + tooltipPosition + smallScreenOffset)
          .attr('dy', biosignalsHistoryChart.tooltip.tooltipHeight === 64 ? '-20' : '0')
          .attr('transform', `translate(0, ${-13})`);
        
        if(biosignalsHistoryChart.tooltip.day.sleepNumber && biosignalsHistoryChart.isHistory) {
          biosignalsHistoryChart.tooltip.createTrademarkInTooltipText(setting, 'sleepNumberSettingText')
        }

        // place tooltip circles
        biosignalsHistoryChart.tooltip.focus.selectAll('.tooltip-circle')
          .style('display', !biosignalsHistoryChart.tooltip.day.value ? 'none' : 'block')
          .attr('transform', `translate(${biosignalsHistoryChart.edgeCaseAtTheBeginning && biosignalsHistoryChart.checkJanuaryEdgeCase() 
                                          ? biosignalsHistoryChart.axis.x(moment(biosignalsHistoryChart.tooltip.day.date)) + 6 
                                          : biosignalsHistoryChart.axis.x(moment(biosignalsHistoryChart.tooltip.day.date))}, ${biosignalsHistoryChart.axis.y(biosignalsHistoryChart.tooltip.day.value)})`);

        // place tooltip line
        biosignalsHistoryChart.tooltip.focus.select('.tooltip-line')
          .attr('transform', `translate(${biosignalsHistoryChart.edgeCaseAtTheBeginning && biosignalsHistoryChart.checkJanuaryEdgeCase() 
                                          ? biosignalsHistoryChart.axis.x(moment(biosignalsHistoryChart.tooltip.day.date)) + 5 
                                          : biosignalsHistoryChart.axis.x(moment(biosignalsHistoryChart.tooltip.day.date)) - 1}, ${biosignalsHistoryChart.tooltip.translateTooltipLine(r, biosignalsHistoryChart.tooltip.day.value, biosignalsHistoryChart.axis.y)})`)
          .attr('height', biosignalsHistoryChart.tooltip.calculateTooltipLineHeight(biosignalsHistoryChart, r, type, maxValue7Days, maxValue30Days));  
      };
    }

    drawFocus();
  }

  // Gradient color creation
  function createGradientColor(svg) {
    const defs = svg.append('defs');

    const gradient = defs.append('linearGradient')
      .attr('id', 'svg-gradient')
      .attr('x1', '100%')
      .attr('x2', '100%')
      .attr('y1', '0%')
      .attr('y2', '100%');

    gradient.append('stop')
      .attr('class', 'start')
      .attr('offset', '0%')
      .attr('stop-color', colors.getColor(biosignalsHistoryChart.formatChartId()))
      .attr('stop-opacity', 1);

    gradient.append('stop')
      .attr('class', 'end')
      .attr('offset', '100%')
      .attr('stop-color', 'transparent')
      .attr('stop-opacity', 1);

    return gradient;
  }

})();