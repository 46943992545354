(function () {
  window.drawHRV = function (hrvValue, min, max) {
    var hrv = 0;
    if (hrvValue >= 0 && hrvValue <= min) {
      hrv = min;
    } else if (hrvValue > max) {
      hrv = max;
    } else {
      hrv = hrvValue;
    }
    const chartContainer = d3.select('#hrv-gauge-chart').append('div').attr('class', 'hrv-chart-container hrv-path');
    const markerContainer = chartContainer.append('div').attr('class', 'marker-container');
    const marker = markerContainer.append('div').attr('class', 'hrv-marker');
    marker.html(
      '<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path id="svg-marker" fill-rule="evenodd" clip-rule="evenodd" d="M3.6347 3.26562C3.87414 2.08838 5.09006 1.38637 6.2293 1.76763L16.3346 5.14952C17.969 5.69648 18.1924 7.91646 16.6999 8.77818L4.47071 15.8387C2.97817 16.7004 1.16734 15.3969 1.51084 13.708L3.6347 3.26562Z" fill="#6BFCAC" stroke="#001D34" stroke-width="2" stroke-linejoin="round"/>' +
      '</svg>'
    );
    setTimeout(function () {
      markerContainer.attr('class', 'marker-container ' + getNewAnimationClass(hrv, min, max));
    }, 400);
  };

  function getNewAnimationClass(hrv, min, max) {
    var percentage = Math.round(100 * ((hrv - min) / (max - min)));
    var percentageCut = percentage - percentage % 10;
    var rest = percentage % 10;
    var percentageRounded = percentageCut;
    if (rest <= 2.5) {
      percentageRounded = percentageCut;
    } else if (rest > 2.5 && rest < 7.5) {
      percentageRounded = percentageCut + 5;
    } else {
      percentageRounded = percentageCut + 10;
    }
    return 'animate-' + percentageRounded;
  }

})();