(function () {
  class TimingChart {
    id;
    data;
    refValues;
    svg;
    xTimeline = [];
    yTimeline = [];
    x;
    y;
    tooltip;
    isEdited = null;

    constructor(id, sessions, isEdited) {
      this.id = id;
      this.data = this.transformData(sessions);
      this.refValues = this.findRefValues(this.data);
      this.isEdited = isEdited;
    }

    transformData(sessions) {
      const formattedSessions = JSON.parse(JSON.stringify(sessions));
      const formattedTimeOfSessions = this.formatTimeOfSessions(formattedSessions);
      return formattedTimeOfSessions;
    }

    setSvg(svg) {
      this.svg = svg;
    }

    setTooltip(tooltip) {
      this.tooltip = tooltip;
    }

    // format time of the session so all goal and session values have the same date
    // this is done so the calculations on the y-axis are easier
    // example
    // first day in crData array -> startDate: 2023-01-23T21:21:26; endDate: 2023-01-24T06:36:06
    // after running the formatTimeOfSessions
    // example startDate: 2023-01-29T21:10:21; endDate: 2023-01-30T06:34:23
    // goal will be startDate: 2023-01-23T22:00:00 endDate: 2023-01-23T23:00:00
    // value will be startDate: 2023-01-23T23:00:34 endDate: 2023-01-24T06:34:23
    formatTimeOfSessions(a) {
      let crData = a;
      const len = crData.length;
      for (var i = 0; i < len; i++) {
        if (crData[i].hasOwnProperty('data') && crData[i].data.timing && !crData[i].data.timing.value && !crData[i].data.timing.goal) {
          delete crData[i].data;
          continue;
        }
        if (crData[i].hasOwnProperty("data") && crData[i].data.timing && crData[i].data.timing.value) {
          crData[i].data.timing.value.bedTime = moment(crData[i].data.timing.value.bedTime).add(-i, 'days')
            .format('YYYY-MM-DDTHH:mm:ss');
          crData[i].data.timing.value.wakeTime = moment(crData[i].data.timing.value.wakeTime).add(-i, 'days')
            .format('YYYY-MM-DDTHH:mm:ss');
          crData[i]['start'] = moment(crData[i].data.timing.value.bedTime).seconds(0).valueOf();
          crData[i]['end'] = moment(crData[i].data.timing.value.wakeTime).seconds(0).valueOf();
        }
        if (crData[i].hasOwnProperty("data") && crData[i].data.timing && crData[i].data.timing.goal && crData[i].data.timing.goal.bedTime) {
          crData[i].data.timing.goal.bedTime.startDate = moment(crData[i].data.timing.goal.bedTime.startDate).add(-i, 'days').format('YYYY-MM-DDTHH:mm:ss');
          crData[i].data.timing.goal.bedTime.endDate = moment(crData[i].data.timing.goal.bedTime.endDate).add(-i, 'days').format('YYYY-MM-DDTHH:mm:ss');
          crData[i].data.timing.goal.bedTime['s'] = moment(crData[i].data.timing.goal.bedTime.startDate).valueOf();
          crData[i].data.timing.goal.bedTime['e'] = moment(crData[i].data.timing.goal.bedTime.endDate).valueOf();
        }
        if (crData[i].hasOwnProperty("data") && crData[i].data.timing && crData[i].data.timing.goal && crData[i].data.timing.goal.wakeTime) {
          crData[i].data.timing.goal.wakeTime.startDate = moment(crData[i].data.timing.goal.wakeTime.startDate).add(-i, 'days').format('YYYY-MM-DDTHH:mm:ss');
          crData[i].data.timing.goal.wakeTime.endDate = moment(crData[i].data.timing.goal.wakeTime.endDate).add(-i, 'days').format('YYYY-MM-DDTHH:mm:ss');
          crData[i].data.timing.goal.wakeTime['s'] = moment(crData[i].data.timing.goal.wakeTime.startDate).valueOf();
          crData[i].data.timing.goal.wakeTime['e'] = moment(crData[i].data.timing.goal.wakeTime.endDate).valueOf();
        }
      }
      return crData;
    }

    // find referent values for the y-axis timeline
    // the referent value can be either goal or a session
    // see documentation for visual
    findRefValues(crData) {
      var start = null, end = null;
      if (crData.length > 0) {
        start = crData[0].start;
        end = crData[0].end;
      }
      if (!start && !end) {
        const session = crData.find(data => data.start && data.end);
        start = session.start;
        end = session.end;
      }
      for (var i = 0; i < crData.length; i++) {
        if (crData[i].hasOwnProperty('absenceCode') && crData[i].absenceCode === -2) { continue; }
        var tmpStart = crData[i].start;
        var tmpEnd = crData[i].end;
        if (start > tmpStart) {
          start = tmpStart;
        }
        if (end < tmpEnd) {
          end = tmpEnd;
        }
        if (crData[i].hasOwnProperty("data") && crData[i].data.timing && crData[i].data.timing.goal && crData[i].data.timing.goal.bedTime) {
          tmpStart = crData[i].data.timing.goal.bedTime.s;
          if (start > tmpStart) {
            start = tmpStart;
          }
        }
        if (crData[i].hasOwnProperty("data") && crData[i].data.timing && crData[i].data.timing.goal && crData[i].data.timing.goal.wakeTime) {
          tmpEnd = crData[i].data.timing.goal.wakeTime.e;
          if (end < tmpEnd) {
            end = tmpEnd;
          }
        }
      }
      return {
        start: start,
        end: end
      };
    }

    calculateXTimeline() {
      this.xTimeline = this.data.map(d => moment(d.sessionDate));
      this.x = d3.scaleTime().domain([this.xTimeline[0], this.xTimeline[this.xTimeline.length - 1]]).range([0, this.svg.container.xAxisWidth - this.svg.container.yAxisWidth - 25]);
    }

    // use referent values as max and min start/end value
    calculateYTimeline() {
      const startOffset = moment(this.refValues.start).minutes() * 60 + moment(this.refValues.start).seconds();
      const endOffset = moment(this.refValues.end).minutes() * 60 + moment(this.refValues.end).seconds();
      const THIRTY_MINUTES = 30 * 60;
      const ONE_HOUR = 3600000;

      var timelineStart, timelineEnd;

      // If the minutes of that timestamp is < 30, the hour is subtracted. 
      // Then, that timestamp is truncated to the hour part. So, if the timestamp is 20:34, 
      // the timeline start will be 20:00, 
      // and if the timestamp is 20:28, the timeline start will be 19:00.
      if (startOffset >= THIRTY_MINUTES) {
        timelineStart = moment(this.refValues.start).add(-startOffset, 'seconds');
      } else {
        timelineStart = moment(this.refValues.start).add(-1, 'hour').add(-startOffset, 'seconds');
      }

      // If the minute part of that timestamp is >= 30, the hour is added. 
      // Then, the hour is added and the calculated timestamp is truncated to the hour part. 
      // So, if the timestamp is 08:28, the timeline end will be 09:00, and if the timestamp is 08:34, 
      // the timeline end will be 10:00.
      if (endOffset <= THIRTY_MINUTES) {
        timelineEnd = moment(this.refValues.end).add((ONE_HOUR / 1000) - endOffset, 'seconds');
      } else {
        timelineEnd = moment(this.refValues.end).add(1, 'hour').add((ONE_HOUR / 1000) - endOffset, 'seconds');
      }

      for (var i = timelineStart.valueOf(); i <= timelineEnd.valueOf(); i += ONE_HOUR) {
        this.yTimeline.push(i);
      }

      this.y = d3.scaleTime().domain([this.yTimeline[0], this.yTimeline[this.yTimeline.length - 1]]).range([this.svg.container.yAxisHeight, 63]);
    }

    isSessionInsideCR(session) {
      return session.hasOwnProperty("data") && session.data.timing && session.data.timing.goalMet;
    }

    calculateXPosition(date, padding) {
      return this.x(moment(date)) + padding;
    }
  }

  class TimingSvg {
    svg;
    width = 500;
    height = 300;
    yAxisWidth = 25;
    yAxisHeight = 280;
    xAxisWidth = 300;
    xAxisHeight = 20;
    tooltipHeight = 63;
    color = null;

    constructor(svg, width, height) {
      this.svg = svg;
      this.width = width;
      this.height = height;
      this.color = new TimingSvgColors();
    }

    get container() {
      return {
        height: this.height + this.tooltipHeight,
        yAxisHeight: this.yAxisHeight,
        xAxisHeight: this.xAxisHeight,
        width: this.width,
        yAxisWidth: this.yAxisWidth,
        xAxisWidth: this.width - 20,
      };
    }
  }

  class TimingSvgColors {
    white = '#F2F5F7';
    yellow = '#FFC511';
    editedLabel = '#99ABBD';
  }

  class TimingChartTooltip {
    tooltipTextHelper = {
      'wake-time': 'Wake time',
      'bedtime': 'Bedtime'
    };

    tooltipColorHelper = {
      'text-color': '#E6EBED',
      'body-color': '#335978',
      'background-color': '#001D34',
      'yellow': '#FFC511',
      'white': '#F2F5F7'
    };

    tooltipHeight = null;
    tooltipWidth = null;
    tooltipMargin = 4; 
    tooltipPadding = 8;
    tooltipTextLength = null;
    day = null;
    dayIndex = null;
    focus = null;
    dateText = null;
    wakeTimeText = null;
    bedtimeText = null;

    constructor(focus) {
      this.focus = focus;
    }

    get isNoDataText() {
      return !this.day?.end
    }

    setDay(day) {
      this.day = day;
    }

    setDayIndex(dayIndex) {
      this.dayIndex = dayIndex;
    }

    setTooltipText() {
      this.dateText = moment(this.day.sessionDate).format('MMM D');
      this.wakeTimeText = this.day.end ? `${this.tooltipTextHelper['wake-time']}: ${moment(this.day.end).format('h:mm A')}` : 'No data';
      this.bedtimeText = this.day.start ? `${this.tooltipTextHelper['bedtime']}: ${moment(this.day.start).format('h:mm A')}` : '';
    }

    setTooltipWidth() {
      this.tooltipTextLength = this.wakeTimeText.length >= this.bedtimeText.length ? this.getTextWidth(this.wakeTimeText, 14) : this.getTextWidth(this.bedtimeText, 14);
      this.tooltipWidth = this.tooltipTextLength + 2 * this.tooltipPadding;
    }

    setTooltipHeight() {
      this.tooltipHeight = this.day.start ? '63' : '45';
    }

    getTextWidth(text, font) {
      let textElement = document.createElement('span');
      document.body.appendChild(textElement);
  
      textElement.style.font = 'Avenir_500';
      textElement.style.fontSize = font;
      textElement.style.lineHeight = 18;
      textElement.style.height = 'auto';
      textElement.style.width = 'auto';
      textElement.style.position = 'absolute';
      textElement.style.whiteSpace = 'no-wrap';
      textElement.style.color = 'transparent';
      textElement.innerHTML = text;
      const width = Math.ceil(textElement.clientWidth);
  
      document.body.removeChild(textElement);
  
      return width;
    }

    // Creating tooltip elements
    createTooltipBody() {
      this.focus.append('rect')
        .attr('class', 'tooltip-body')
        .attr('rx', 4)
        .attr('ry', 4)
        .style('display', 'none')
        .style('fill', this.tooltipColorHelper['body-color'])
        .exit().remove();
    }

    createText(textClass) {
      this.focus.append('text')
        .attr('class', textClass)
        .style('fill', this.tooltipColorHelper['text-color'])
        .style('font-size', '14px')
        .style('line-height', '18px')
        .style('font-family', 'Avenir_400')
    }

    createTooltipLine() {
      this.focus.append('rect')
        .attr('class', 'tooltip-line')
        .attr('width', 2)
        .attr('fill', this.tooltipColorHelper['text-color'])
        .attr('rx', 1)
        .style('display', 'none')
        .exit().remove();
    }

    createTooltipTip(isGoalMet, rectId) {
      const classHelper = {
        goalMet: {
          wakeTimeGoal: 'tooltip-tip-wake-time-goal-met',
          bedtimeGoal: 'tooltip-tip-bedtime-goal-met'
        },
        goalNotMet: {
          wakeTimeGoal: 'tooltip-tip-wake-time-goal-not-met',
          bedtimeGoal: 'tooltip-tip-bedtime-goal-not-met'
        }
      }

      const goalMet = isGoalMet ? 'goalMet' : 'goalNotMet',
            goalClass = rectId.includes('wake-time') ? 'wakeTimeGoal' : 'bedtimeGoal';

      // create outer circle this is common for both diamond and circle
      this.focus.append('circle')
        .attr('class', classHelper[goalMet][goalClass])
        .attr('id', 'outer-circle')
        .attr('fill', this.tooltipColorHelper['background-color'])
        .attr('stroke', 'black')
        .attr('stroke-width', 2)
        .attr('r', 10)
        .style('display', 'none');
  
      // based on the condition add diamond or circle as an inner element
      if (isGoalMet) {
        this.focus.append('circle')
          .attr('class', classHelper['goalMet'][goalClass])
          .attr('id', 'inner-circle')
          .attr('stroke', this.tooltipColorHelper['background-color'])
          .attr('stroke-width', 2)
          .attr('r', 6)
          .style('fill', 'transparent')
          .style('display', 'none');
      } else {
        this.focus.append('rect')
          .attr('class', classHelper['goalNotMet'][goalClass])
          .attr('id', rectId)
          .attr('stroke', this.tooltipColorHelper['background-color'])
          .attr('stroke-width', 2)
          .attr('width', '12px')
          .attr('height', '12px')
          .style('fill', 'transparent')
          .attr('rx', 2)
          .style('display', 'none');
      }
    }

    appendTimeToText(value, textProperty, startIndex, endIndex) {
      value.append('tspan')
        .text(timingChart.tooltip[textProperty].split(' ').slice(startIndex, endIndex).join(' '))
        .attr('dy', '0')
        .attr('dx', '3')
        .style('font-family', 'Avenir_900')
        .style('font-size', '14px')
        .style('font-weight', 900);
    }

    // Tooltip positions
    placeTooltip(x, marginLeft, padding_12, padding_70) {
      const currentDayXPosition = x(moment(this.day.sessionDate));
      // rest of the tooltip that will stay visible on right/left side when tooltip goes out of the chart scope
      const leftOffset = (currentDayXPosition - this.tooltipWidth / 2);
      if(leftOffset < -marginLeft) {
        // move tooltip for the invisible part 
        // 46 is padding in calculateXPosition function and 12 to make space between start of the chart and tooltip
        return -leftOffset - padding_70 + padding_12;
      }
      return 0;
    }

    placeWakeTimeTooltipTip(element, xPosition) {
      const prop = element.includes('wake-time') ? 'start' : 'end';
      this.focus.selectAll(element)
            .style('display', 'block')
            .attr('transform', `translate(${xPosition}, ${timingChart.y(timingChart.data[this.dayIndex][prop]) + 9})`);
      this.focus.selectAll('#outer-circle')
        .attr('stroke', this.tooltipColorHelper['yellow']);
      this.focus.selectAll('#inner-circle')
        .style('fill', this.tooltipColorHelper['yellow']);
    }

    placeBedtimeTooltipTip(element, xPosition, rectId) {
      const prop = element.includes('wake-time') ? 'start' : 'end';
      this.focus.selectAll(element)
            .style('display', 'block')
            .attr('transform', `translate(${xPosition}, ${timingChart.y(timingChart.data[this.dayIndex][prop]) + 9})`);
      this.focus.selectAll('#outer-circle')
        .attr('stroke', this.tooltipColorHelper['white']);
      this.focus.selectAll(rectId)
        .style('fill', this.tooltipColorHelper['white'])
        .attr('x', xPosition)
        .attr('transform', `translate(${0}, ${0})rotate(45 ${xPosition} ${timingChart.y(timingChart.data[this.dayIndex][prop])})`)
        .attr('y', timingChart.y(timingChart.data[this.dayIndex][prop]))
    }

    centerTooltipTextHorizontally(x, text, padding) {
      // first of all - place the text at the beginning of tooltip body
      const xStart = Math.floor(x(moment(this.day.sessionDate)) - this.tooltipWidth / 2) + padding;
      // move the text to the center of tooltip body
      // count the remaining empty space on the tooltip and divide it by 2   
      const xCenter = Math.floor((this.tooltipWidth - this.getTextWidth(text, 14)) / 2); 
      
      return xStart + xCenter;
    }

    resetFocusElement(element) {
      this.focus.selectAll(element)
        .style('display', 'none');
    }

  }

  let timingChart = null;
  const paddings = {
    padding_5: 5,
    padding_8: 8,
    padding_12: 12,
    padding_20: 20,
    padding_40: 40,
    padding_46: 46,
    padding_60: 60,
    padding_70: 70
  };

  // gets hovered date
  var bisectDate = d3.bisector(function (d) {
    return moment(d.sessionDate);
  }).left;

  window.generateCRChart = function (sessions, sleeperId, isEdited) {
    const chartId = sleeperId ? '#cr-chart' + sleeperId : '#sleep-health-timing-chart';
    const containerWidth = d3.select('.sleep-health-chart-container').node().getBoundingClientRect().width;
    timingChart = new TimingChart(chartId, sessions, isEdited);
    svg = new TimingSvg(containerWidth);
    timingChart.setSvg(svg);
    clearChart(timingChart.id);
    updateContainer(timingChart.id);
    drawChart();

    window.onresize = function (event) {
      clearChart(timingChart.id);
      updateContainer(timingChart.id);
      drawChart();
    };
  };

  function clearChart(id) {
    d3.selectAll(id + ' svg').remove();
    d3.selectAll('.tooltip').remove();
  }

  function updateContainer(id) {
    const containerWidth = d3.select('.sleep-health-chart-container').node().getBoundingClientRect().width;
    svg = new TimingSvg(containerWidth);
    timingChart.setSvg(svg);
  }

  function drawChart() {
    const tooltipHeight = 63; // this is by design
    const containerWidth = d3.select('.sleep-health-chart-container').node().getBoundingClientRect().width;
    const containerHeight = d3.select('.sleep-health-chart-container').node().getBoundingClientRect().height;
    const svg = d3.select(timingChart.id).append('svg')
      .attr('width', containerWidth)
      .attr('height', containerHeight + tooltipHeight + (timingChart.isEdited ? 16 : 0)) // 16 is height of the "Edited" label
      .attr('viewBox', `30 -${timingChart.isEdited ? 66 : 50} ${containerWidth} ${containerHeight + 150 + (timingChart.isEdited ? 16 : 0)}`)
      .attr('xmlns', 'http://www.w3.org/2000/svg')
      .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
      .attr('xmlns:svgjs', 'http://svgjs.com/svgjs')
      .style('overflow', 'visible');

    timingChart.setSvg(new TimingSvg(svg, containerWidth, containerHeight));

    timingChart.calculateXTimeline();
    timingChart.calculateYTimeline();

    // edited label
    if(timingChart.isEdited) {
      createEditedLabel(containerWidth);
    }

    createXAxis();

    createXAxisDate();

    createYAxis();

    createGoalRect('bedTime');

    createGoalRect('wakeTime');

    createSession();

    createTip();

    drawFocus();
  }

  // #region edited label
  function createEditedLabel(containerWidth) {
    timingChart.svg.svg
      .append("text")
      .attr("x", containerWidth / 2)
      .attr("y", paddings.padding_20)
      .text("Edited")
      .style('fill', timingChart.svg.color.editedLabel)
      .style('font-family', 'Avenir_400')
      .style('font-size', '14px')
      .style('line-height', '18px')
      .style('font-weight', 400)
      .exit().remove();
  }
  // #endregion

  //#region create x axis
  function createXAxis() {
    timingChart.svg.svg
      .append('g')
      .attr('id', 'x-axis-timing')
      .attr('class', 'axis')
      .attr(
        'transform',
        `translate(${paddings.padding_70},${timingChart.svg.container.yAxisHeight + paddings.padding_40})`
      )
      .call(
        d3
          .axisBottom(timingChart.x)
          .tickValues(timingChart.xTimeline)
          .tickFormat((d) => d3.timeFormat("%a")(d).slice(0, 2)) // to take only first two letters of a day Sat -> Sa
          .tickSize(0)
      )
      .call(g => g.select('.domain').remove())
      .exit().remove();
  }

  function createXAxisDate() {
    timingChart.svg.svg
      .append('g')
      .attr('id', `x-axis-date-timing`)
      .attr('class', 'axis')
      .attr(
        'transform',
        `translate(${paddings.padding_70},${timingChart.svg.container.yAxisHeight + paddings.padding_60})`
      )
      .call(
        d3
          .axisBottom(timingChart.x)
          .tickValues(timingChart.xTimeline)
          .tickFormat((d, i) => {
            if (i === 0 || i === timingChart.data.length - 1) {
              return d.format('M/D');
            }
          })
          .tickSize(0)
      )
      .call(g => g.select('.domain').remove())
      .exit().remove();
  }

  //#endregion

  //#region create y axis
  function createYAxis() {
    const yAxisTimeline = timingChart.svg.svg.append('g')
      .attr('id', 'y-timeline-container')
      .attr('class', 'axis')
      .attr('transform', `translate(0, ${paddings.padding_20})`);
    const numberOfTicks = 4;
    const minYValue = timingChart.y.domain()[0].valueOf();
    const maxYValue = timingChart.y.domain()[1].valueOf();
    var step = moment(Math.floor(maxYValue - minYValue) / (numberOfTicks - 1)).startOf('hour').valueOf();
    for (var i = 0; i < numberOfTicks; i++) {
      const yValue = i === numberOfTicks - 1 ? maxYValue : minYValue + i * step;
      const offset = 5;
      const xValue = moment(yValue).format('h').length > 1 ? 6 : 12;
      const text = yAxisTimeline.append('text')
        .attr('x', xValue)
        .attr('y', timingChart.y(yValue) - offset)
        .attr('style', 'font: 12px')
        .attr('fill', timingChart.svg.color.white);

      text.append('tspan').text(moment(yValue).format(' h'));
      const partOfDayOffset = -10;
      if (i === 0 || i === numberOfTicks - 1) {
        text.append('tspan')
          .attr('x', 2)
          .attr('y', timingChart.y(yValue) - partOfDayOffset)
          .text(moment(yValue).format('A'));
      }
    }
  }
  //#endregion

  //#region create goal 
  function createGoalRect(prop) {
    const rectData = transformDataForGoal(prop);
    timingChart.svg.svg
      .append('g')
      .attr('id', `goal-rect-${prop}`)
      .selectAll(`goal-rect-${prop}`)
      .data(rectData.width)
      .enter()
      .append("rect")
      .attr('transform', `translate(${paddings.padding_46},${-paddings.padding_5})`)
      .attr('x', (d, i) => rectData.x[i])
      .attr('y', (d, i) => rectData.y[i])
      .attr('width', (d, i) => rectData.width[i] + paddings.padding_40)
      .attr('height', (d, i) => rectData.height[i])
      .attr('rx', 2)
      .style('fill', timingChart.svg.color.yellow)
      .style('opacity', '0.2')
      .style('stroke-width', 2)
      .style('stroke', 'none')
      .exit().remove();
  }

  function transformDataForGoal(prop) {
    const data = timingChart.data;
    const goalRectWidths = [];
    const goalRectHeight = [];
    const x0 = JSON.parse(JSON.stringify(timingChart.xTimeline[0]));
    const y0 = data[0].hasOwnProperty('data') && data[0].data.timing && data[0].data.timing.goal && !data[0].data.timing.goal.hasOwnProperty('reason') ? data[0].data.timing.goal[prop].s : 0;
    const xValues = [timingChart.x(moment(x0).add(-3, 'hours'))];
    const yValues = [timingChart.y(y0)];
    let rectWidth = 0;
    let goal = y0;
    for (let i = 0; i < data.length; i++) {
      const nextGoal = data[i].hasOwnProperty('data') && data[i].data.timing && data[i].data.timing.goal && !data[0].data.timing?.goal.hasOwnProperty('reason') ? data[i].data.timing.goal[prop]?.s : 0;
      if (goal !== nextGoal) {
        goalRectWidths.push(rectWidth);
        rectWidth = calculateRectWidth(timingChart.xTimeline[i], i);
        goalRectHeight.push(calculateRectHeight(i - 1, prop));
        const x = JSON.parse(JSON.stringify(timingChart.xTimeline[i]));
        xValues.push(timingChart.x(moment(x).add(-12.5, 'hours')));
        if(data[i].data && data[i].data.timing && data[i].data.timing.goal) {
          yValues.push(timingChart.y(data[i].data.timing.goal[prop]?.s));
        }
        goal = nextGoal;
      } else {
        rectWidth += calculateRectWidth(timingChart.xTimeline[i], i);
      }
    }
    goalRectWidths.push(rectWidth);
    goalRectHeight.push(calculateRectHeight(data.length - 1, prop));
    return { width: goalRectWidths, height: goalRectHeight, x: xValues, y: yValues };
  }

  function calculateRectWidth(date, index) {
    // add check for first and last
    // first -> x0 - 6
    // last -> xL + 12
    switch (index) {
      case 0:
        return Math.floor(timingChart.x(moment(date).add(12, 'hours')) - timingChart.x(moment(date).add(-6, 'hours')));
      case timingChart.data.length - 1:
        return Math.floor(timingChart.x(moment(date).add(6, 'hours')) - timingChart.x(moment(date).add(-12, 'hours')));
      default:
        return Math.floor(timingChart.x(moment(date).add(12, 'hours')) - timingChart.x(moment(date).add(-12, 'hours')));
    }
  }

  function calculateRectHeight(position, prop) {
    const data = timingChart.data;
    const y2 = data[position].hasOwnProperty('data') && data[position].data.timing && data[position].data.timing.goal && !data[position].data.timing.goal.hasOwnProperty('reason') ? data[position].data.timing.goal[prop].e : 0;
    const y1 = data[position].hasOwnProperty('data') && data[position].data.timing && data[position].data.timing.goal && !data[position].data.timing.goal.hasOwnProperty('reason') ? data[position].data.timing.goal[prop].s : 0;
    return Math.abs(timingChart.y(y2) - timingChart.y(y1));
  }
  //#endregion

  //#region create session
  function createSession() {
    timingChart.svg.svg
      .append('g')
      .selectAll('line')
      .data(timingChart.data)
      .enter()
      .append('line')
      .attr('y1', (d) => timingChart.y(d.start))
      .attr('x1', (d, i) => timingChart.x(timingChart.xTimeline[i]))
      .attr('y2', (d) => timingChart.y(d.end))
      .attr('x2', (d, i) => timingChart.x(timingChart.xTimeline[i]))
      .attr('transform', `translate(${paddings.padding_70},${paddings.padding_8})`)
      .attr('fill', 'none')
      .attr('stroke', (d) => timingChart.isSessionInsideCR(d) ? timingChart.svg.color.yellow : timingChart.svg.color.white)
      .attr('stroke-width', 2)
      .exit().remove();
  }
  //#endregion

  //#region session tip 
  function createTip() {
    const tip = timingChart.svg.svg
      .append('g')
      .attr('id', `bar-tip`);
    for (var i = 0; i < timingChart.data.length; i++) {
      if (timingChart.data && timingChart.data[i] && timingChart.data[i].absenceCode !== -2 && timingChart.data[i].data && timingChart.data[i].data.timing && !timingChart.data[i].data.timing.goalMet) {
        createBarDiamondTip(tip, i, [timingChart.data[i].start, timingChart.data[i].end], timingChart.svg.color.white);
      } else if (timingChart.data && timingChart.data[i] && timingChart.data[i].absenceCode !== -2 && timingChart.data[i].data && timingChart.data[i].data.timing && timingChart.data[i].data.timing.goalMet) {
        createBarCircleTip(tip, i, [timingChart.data[i].start, timingChart.data[i].end], timingChart.svg.color.yellow);
      }
    }

  }
  //#endregion 

  // #region diamond bar tip
  function createBarDiamondTip(element, index, data, color) {
    element
      .selectAll('#bar-tip')
      .data(data)
      .enter()
      .append('rect')
      // get center point 
      .attr('transform', (d) => `translate(${paddings.padding_70}, ${0})rotate(45 ${timingChart.x(timingChart.xTimeline[index])} ${timingChart.y(d)})`)
      .attr('x', timingChart.x(timingChart.xTimeline[index]))
      .attr('y', (d) => timingChart.y(d))
      .attr('width', '12px')
      .attr('height', '12px')
      .attr('rx', 2)
      .attr('fill', color)
      .exit().remove();
  }
  // #endregion

  //#region circle bar tip
  function createBarCircleTip(element, index, data, color) {
    element
      .selectAll('#bar-tip')
      .data(data)
      .enter()
      .append('circle')
      .attr('transform', `translate(${paddings.padding_70}, ${paddings.padding_12})`)
      .attr('cx', timingChart.x(timingChart.xTimeline[index]))
      .attr('cy', (d) => timingChart.y(d))
      .attr('r', 6)
      .attr('fill', color);
  }
  //#endregion

  function drawFocus() {
    //Create focus object;
    let focus = timingChart.svg.svg.append('g').attr('class', 'focus');

    // Create tooltip object
    let tooltip = new TimingChartTooltip(focus);

    // create goal met tooltip tip
    tooltip.createTooltipTip(true, 'inner-rect-wake-time'); // wake time goal met
    tooltip.createTooltipTip(true, 'inner-rect-bedtime'); // bedtime goal met

    // create goal not met tooltip tip
    tooltip.createTooltipTip(false, 'inner-rect-wake-time'); // wake time goal not met
    tooltip.createTooltipTip(false, 'inner-rect-bedtime'); // bedtime goal not met

    // add background rectangle behind the text tooltip
    tooltip.createTooltipBody();

    // append vertical line below the circle
    tooltip.createTooltipLine();

    // add the first line of text annotation for tooltip
    tooltip.createText('date-text');

    // add the second line of text annotation for tooltip
    tooltip.createText('wake-time-text');

    // add the third line of text annotation for tooltip
    tooltip.createText('bedtime-text');

    // create an overlay rectangle to draw the above objects on top of
    timingChart.svg.svg.append('rect')
      .attr('class', 'overlay')
      .attr('width', timingChart.svg.container.width)
      .attr('height', timingChart.svg.container.height)
      .attr(
        'transform',
        `translate(${paddings.padding_5},${0})`
      )
      .attr('x', 2)
      .on('mouseover', () => focus.style('display', null))
      .on('mouseout', () => focus.style('display', 'none'))
      .on('mousemove', () => tipMove());

    // make the overlay rectangle transparent,
    // so it only serves the purpose of detecting mouse events
    d3.selectAll('.overlay')
      .style('fill', 'none')
      .style('pointer-events', 'all');

    //#region tip move
    // function that adds tooltip on hover
    function tipMove() {

      const d = getHoveredDate();
      const hoveredDate = date => date.sessionDate === d.sessionDate;

      tooltip.setDay(d);
      tooltip.setDayIndex(timingChart.data.findIndex(hoveredDate));

      // Set values for tooltip text
      tooltip.setTooltipText();

      // Set values for tooltip dimensions
      tooltip.setTooltipWidth();
      tooltip.setTooltipHeight();

      // Set tooltip to the chart
      timingChart.setTooltip(tooltip);

      const tooltipXPosition = timingChart.calculateXPosition(timingChart.tooltip.day.sessionDate, paddings.padding_70);
      const tooltipPosition = timingChart.tooltip.placeTooltip(timingChart.x, margin.left, paddings.padding_12, paddings.padding_70);

      // place tooltip body
      timingChart.tooltip.focus.select('.tooltip-body')
        .attr('x', tooltipXPosition + tooltipPosition)
        .attr('width', timingChart.tooltip.tooltipWidth)
        .attr('height', timingChart.tooltip.tooltipHeight)
        .attr('transform', `translate(${-timingChart.tooltip.tooltipWidth / 2}, ${timingChart.tooltip.day.end ? '0' : '18'})`)
        .style('display', 'block');

      // place tooltip text
      timingChart.tooltip.focus.select('.date-text').text(timingChart.tooltip.dateText)
        .attr('x', timingChart.tooltip.centerTooltipTextHorizontally(timingChart.x, timingChart.tooltip.dateText, paddings.padding_46) + tooltipPosition)
        .attr('transform', `translate(24, ${timingChart.tooltip.day.end ? '18' : '36'})`);

      let value = timingChart.tooltip.focus.select('.wake-time-text').text(timingChart.tooltip.wakeTimeText.split(' ').slice(0, 2).join(' '))
        .attr('x', timingChart.tooltip.centerTooltipTextHorizontally(timingChart.x, timingChart.tooltip.wakeTimeText, paddings.padding_46) + tooltipPosition)
        .attr('transform', `translate(${timingChart.tooltip.isNoDataText ? '23' : '26'}, ${timingChart.tooltip.day.end ? '35' : '53'})`)
        .style('font-weight', timingChart.tooltip.isNoDataText ? 900 : 400)
        .style('font-family', timingChart.tooltip.isNoDataText ? 'Avenir_900' : 'Avenir_400');

      // add part with time and font weight 900
      timingChart.tooltip.appendTimeToText(value, 'wakeTimeText', 2, 4);

      value = timingChart.tooltip.focus.select('.bedtime-text').text(timingChart.tooltip.bedtimeText.split(' ')[0])
        .attr('x', timingChart.tooltip.centerTooltipTextHorizontally(timingChart.x, timingChart.tooltip.bedtimeText, paddings.padding_46) + tooltipPosition)
        .attr('transform', 'translate(26, 52)');

      // add part with time and font weight 900
      timingChart.tooltip.appendTimeToText(value, 'bedtimeText', 1, 3);

      // reset focus elements
      timingChart.tooltip.resetFocusElement('.tooltip-tip-wake-time-goal-met');
      timingChart.tooltip.resetFocusElement('.tooltip-tip-wake-time-goal-not-met');
      timingChart.tooltip.resetFocusElement('.tooltip-tip-bedtime-goal-met');
      timingChart.tooltip.resetFocusElement('.tooltip-tip-bedtime-goal-not-met');
      timingChart.tooltip.resetFocusElement('.tooltip-line');

      if (timingChart.tooltip.day.data && timingChart.tooltip.day.data.timing && timingChart.tooltip.day.data.timing.value) {
        // if the user has met its goal
        if (d.data.timing && d.data.timing.goalMet) {
          // wake time tip
          timingChart.tooltip.placeWakeTimeTooltipTip('.tooltip-tip-wake-time-goal-met', tooltipXPosition);
          // bedtime tip
          timingChart.tooltip.placeWakeTimeTooltipTip('.tooltip-tip-bedtime-goal-met', tooltipXPosition); 
        } else {
          // if the user did not meet its goal
          // wake time tip 
          timingChart.tooltip.placeBedtimeTooltipTip('.tooltip-tip-wake-time-goal-not-met', tooltipXPosition, '#inner-rect-wake-time');
          // bedtime tip 
          timingChart.tooltip.placeBedtimeTooltipTip('.tooltip-tip-bedtime-goal-not-met', tooltipXPosition, '#inner-rect-bedtime');
        }
      } else {
        // if the user hovered over no data day
        timingChart.tooltip.focus.select('.tooltip-line')
          .style('display', 'block');
  
        // place tooltip line
        timingChart.tooltip.focus.select('.tooltip-line')
          .attr('transform', `translate(${tooltipXPosition}, ${(timingChart.svg.height - margin.bottom) / 2})`)
          .attr('height', 24);
      }  
    };
    //#endregion
  }

  //#region get hovered date
  // below code finds the date by bisecting and stores the x and y coordinate as variables
  function getHoveredDate() {
    let x0 = timingChart.x.invert(d3.pointer(event)[0]);
    let i = bisectDate(timingChart.data, x0, 1);
    let d0 = timingChart.data[i - 1];
    let d1 = timingChart.data[i - 1];
    let d = x0 - d0.sessionDate > d1.sessionDate - x0 ? d1 : d0;
    return d;
  }
  //#endregion
})();
