(function () {
  var session = {};
  var sliceClasses = ['hidden-slice', 'out-of-bed', 'restless', 'restful', 'fall-asleep'];

  var draggers = {};

  var variables = {
    tooltip: false,
    editable: false
  };

  var timeFormats = {
    hourMinutesFormatSpace: '%-I:%M %p',
    hourFormatNoP: '%-I',
    formatP: '%p',
    ISONotimezone: '%Y-%m-%dT%H:%M:%S'

  };
  const HOUR = 1000 * 60 * 60;
  const FOUR_HOURS = 4 * 60 * 60;

  var leftShiftX, rightShiftX;

  window.setData = function (sleepData, options) {
    draggers = {};
    resetVariables(options);
    session = formatSession(sleepData);
    calculateSlices();
    var sleeperId = options && options.sleeperId ? options.sleeperId : '';
    var id = "#sleep-data-chart" + sleeperId;
    clearChart(id);
    drawChart(id);

    window.onresize = function (event) {
      clearChart(id);
      drawChart(id);
    };
  };

  window.getEditSession = function () {
    // add back original seconds so API doesn't take away a second when saving an edited session.
    const originalStartDateSeconds = new Date(session.originalStartDate).getSeconds();
    const originalEndDateSeconds = new Date(session.originalEndDate).getSeconds();
    const daggerLeftTimeISOFormat = d3.timeFormat(timeFormats.ISONotimezone)(draggers.leftDraggerTime);
    const daggerRightTimeISOFormat = d3.timeFormat(timeFormats.ISONotimezone)(draggers.rightDraggerTime);
    const sessionDuration = moment(draggers.rightDraggerTime).diff(moment(draggers.leftDraggerTime), 'seconds');

    // for the sessions below 4h, the seconds from the opposite dagger will be added in drag functions
    // if start and end are edited then add original seconds otherwise do nothing
    if (daggerLeftTimeISOFormat !== session.originalStartDate) {
      draggers.leftDraggerTime = addSeconds(draggers.leftDraggerTime, originalStartDateSeconds);;
    }
    if (daggerRightTimeISOFormat !== session.originalEndDate) {
      draggers.rightDraggerTime = addSeconds(draggers.rightDraggerTime, originalEndDateSeconds);
    }

    return JSON.stringify({
      startTime: d3.timeFormat(timeFormats.ISONotimezone)(draggers.leftDraggerTime),
      endTime: d3.timeFormat(timeFormats.ISONotimezone)(draggers.rightDraggerTime)
    });
  };

  function drawChart(id) {
    drawChartElements(id);
    drawSleepSlices(id);

    if (variables.tooltip) {
      drawTooltips(id);
    }

    if (variables.editable) {
      drawDraggers();
    }

    animateChart(id);

    if (variables.tooltip || variables.editable) {
      calculateTimeline(id);
      drawTimeline(id);
    } else {
      drawSmallTimeline(id);
    }
  }

  function calculateTimeline(id) {
    var expandTimelineTime = HOUR;
    session.timeline = {
      start: null,
      end: null,
      values: []
    };
    var chartStart, chartEnd;

    // if there is a tooltip and session is not edited
    // original times will be used
    // else edited times will be used (start and end)
    if (variables.tooltip || variables.editable) {
      chartStart = session.originalStartTimeTenMinutes;
      chartEnd = session.originalEndTimeTenMinutes;
      if (variables.tooltip && isEdited(session)) {
        chartStart = session.startTimeTenMinutes;
        chartEnd = session.endTimeTenMinutes;
      }
    }

    // if there is a short session, timeline will be expanded by 20 minutes
    if ((session.originalEndTime.getTime() - session.originalStartTime.getTime()) < HOUR) {
      expandTimelineTime = HOUR / 3;
    }

    // expand timeline
    session.timeline.start = new Date(chartStart.getTime() - expandTimelineTime);
    session.timeline.end = new Date(chartEnd.getTime() + expandTimelineTime);

    // round start and end
    //|---------------------------------------|
    // example
    // startDate = 2022-10-19T23:13:46
    //endDate = 2022-10-20T07:02:51

    // extend timeline
    //extendStartDate = 2022-10-19T22:13:46
    //extendEndDate = 2022-10-20T08:02:51

    // round timeline
    //roundStart = 2022-10-19T23:00:00
    //roundEnd = 2022-10-20T08:00:00
    //|---------------------------------------|
    var startRound = new Date(session.timeline.start.getTime() - (session.timeline.start.getTime() % HOUR) + HOUR);
    var endRound = new Date(session.timeline.end.getTime() - (session.timeline.end.getTime() % HOUR));

    // if difference between rounded start/end value and the session start is less than 10 minutes, an hour will be added/taken
    if ((startRound.getTime() - session.timeline.start.getTime()) < (HOUR / 6)) {
      startRound = new Date(startRound.getTime() + HOUR);
    }
    if ((session.timeline.end.getTime() - endRound.getTime()) < (HOUR / 6)) {
      endRound = new Date(endRound.getTime() - HOUR);
    }

    // creates timeline values array
    for (var i = startRound.getTime(); i <= endRound.getTime(); i += HOUR) {
      session.timeline.values.push(i);
    }
  }

  function isEdited(session) {
    return (session.originalStartTime.getTime() !== session.startTime.getTime()) ||
      (session.originalEndTime.getTime() !== session.endTime.getTime());
  }

  function drawTimeline(id) {
    var tmp = id + ' .timeline-layer';
    var timelineLayer = d3.select(tmp);
    timelineLayer.attr('class', 'timeline-layer details');
    var chartWidth = document.querySelectorAll(tmp)[0].clientWidth - 15;
    var widthCoeff = chartWidth / (session.timeline.end.getTime() - session.timeline.start.getTime());
    var tempStart = session.timeline.start;
    for (var i = 0; i < session.timeline.values.length; i++) {
      var tempEnd = new Date(session.timeline.values[i]);
      var width = widthCoeff * (tempEnd.getTime() - tempStart.getTime());
      var timelineTick = timelineLayer.append('div').attr('class', 'timeline-value')
        .attr('style', 'width:' + width + 'px;');
      tempStart = new Date(session.timeline.values[i]);
      // every other tick is drawn
      if (i % 2 === 0) {
        timelineTick.append('span').attr('class', 'time').text(d3.timeFormat(timeFormats.hourFormatNoP)(tempEnd));
        if (i == 0 || i === (session.timeline.values.length - 1) || i === (session.timeline.values.length - 2)) {
          timelineTick.append('span').attr('class', 'time-format').text(d3.timeFormat(timeFormats.formatP)(tempEnd));
        }
      }
    }
  }

  function drawSmallTimeline(id) {
    var tmp = id + ' .timeline-layer';
    var timelineLayer = d3.select(tmp);
    var chartWidth = document.querySelectorAll(tmp)[0].clientWidth;

    var timeDiff = session.endTenMinutes.getTime() - session.startTenMinutes.getTime();
    var widthCoeff = chartWidth / timeDiff;

    var startTime = session.fallAsleepTime;
    var smallTimelineClass = 'small-timeline';

    // if there is a fallAsleep time, then the timeline will start at the end of fallAsleep time
    if (session.fallAsleepTime.getTime() > session.start.getTime()) {
      timelineLayer.append('div').attr('style', 'height: 100%; width: ' + (session.fallAsleepTimeTenMinutes.getTime() - session.startTenMinutes.getTime()) * widthCoeff + 'px;');
    } else {
      startTime = session.start;
    }

    var smallTimeline = timelineLayer.append('div').attr('class', smallTimelineClass).attr('style', `width: ${chartWidth - ((session.fallAsleepTimeTenMinutes.getTime() - session.startTenMinutes.getTime()) * widthCoeff)}px`);
    smallTimeline.append('span').attr('class', 'time-value').text(d3.timeFormat(timeFormats.hourMinutesFormatSpace)(startTime));
    smallTimeline.append('span').attr('class', 'time-value').text(d3.timeFormat(timeFormats.hourMinutesFormatSpace)(session.end));
  }

  function drawChartElements(id) {
    var chart = d3.select(id);
    var sleepChartContainer = chart.append('div').attr('class', 'sleep-chart-container');
    var sleepChartLayer = sleepChartContainer.append('div').attr('class', 'sleep-chart-layer').attr('id', 'sleep-session-chart');
    if (variables.tooltip || variables.editable) {
      sleepChartContainer.attr('class', 'sleep-chart-container details');
      sleepChartLayer.attr('class', 'sleep-chart-layer details-chart');
    }
    if (variables.editable) {
      sleepChartContainer.attr('class', 'sleep-chart-container details edit');
      sleepChartLayer.append('div').attr('class', 'edit-layer').attr('id', 'edit-layer');
    }
    sleepChartContainer.append('div').attr('class', 'timeline-layer');
  }

  function animateChart(id) {
    var item = id + " #sleep-session-chart";
    var chart = d3.select(item);
    chart.attr('class', chart.attr("class") + ' animate');
  }

  function drawSleepSlices(id) {
    var item = id + " #sleep-session-chart";
    var chart = d3.select(item);
    var chartWidth = document.querySelectorAll(item)[0].clientWidth;
    var secondsCount;
    for (i = 0; i < session.slices.length; i++) {
      secondsCount += session.slices[i].seconds;
    }
    var widthCoeff = chartWidth / session.slices.length;

    var fakeSlices = 12;
    // if the session is a short session 4 fake slices will be added to the session (3 on each side)
    // otherwise 6 are added
    // this is due to tooltips drawing
    if ((session.originalEndTime.getTime() - session.originalStartTime.getTime()) < HOUR) {
      fakeSlices = fakeSlices / 3;
    }

    if (variables.tooltip || variables.editable) {
      widthCoeff = chartWidth / (session.slices.length + fakeSlices); // 12 = 6 slices in front and 6 in back
      chart.attr('style', 'width: ' + widthCoeff * session.slices.length + 'px; margin: 0px ' + ((fakeSlices / 2) * widthCoeff) + 'px;');
    }

    var shadow = '';
    var slice, numberOfSlices;
    // get the first slice
    if (session.slices.length > 0) {
      slice = sliceClasses[session.slices[0].sliceType];
      numberOfSlices = 0;
    }
    var i;
    // calculate slice width
    // all slices are max 10 minutes long 
    // example
    // session can look like this (rf - restful, rl-restless, oob-out of bed)
    // |_rf_||_rf_||_rf_||_rl_||_oob_||_rl_||_rl_|
    // on the chart the slices will be drawn:
    // |___rf___||_rl_||_oob_||__rl__|
    // in case of consecutive slices the slice number is incremented by 1;
    for (i = 0; i < session.slices.length; i++) {
      var sliceType = sliceClasses[session.slices[i].sliceType];
      if (sliceType !== slice) {
        // Draw slices
        chart.append('div').attr('class', 'slice ' + slice + shadow).attr('style', 'width:' + widthCoeff * numberOfSlices + 'px;');
        numberOfSlices = 1;
        slice = sliceType;
      } else {
        numberOfSlices += 1;
      }
    }
    chart.append('div').attr('class', 'slice ' + slice + shadow).attr('style', 'width:' + widthCoeff * numberOfSlices + 'px;');
  }

  function calculateSlices() {
    var coeff = 1000 * 60 * 10; // 10 minutes
    // calculate how many sleep slices there are
    var fallAsleepSlices = Math.ceil((session.fallAsleepTime.getTime() - session.originalStartTime.getTime()) / coeff);
    if (fallAsleepSlices <= 0) {
      fallAsleepSlices = 0;
    } else {
      fallAsleepSlices = Math.ceil((session.fallAsleepTimeTenMinutes.getTime() - session.originalStartTimeTenMinutes.getTime()) / coeff);
    }

    // Add fallAsleep slices
    // all slices that fall within fallAsleep time frame, will be shown as fall asleep slice (gray)
    for (var i = 0; i < fallAsleepSlices; i++) {
      session.sliceList[i].sliceType = 4;
    }

    // Add sleep slices
    for (var i = fallAsleepSlices; i < session.sliceList.length; i++) {
      session.sliceList[i].sliceType = session.sliceList[i].type;
    }

    var tenMinutes = 10 * 60 * 1000;
    // calculates edited sleep slices 
    // example
    // original sleep session had 60 slices, after editing only 40 of those slice are now part of the session
    var min = Math.ceil((session.start.getTime() - session.originalStartTime.getTime()) / tenMinutes);
    var max = Math.ceil((session.originalEndTime.getTime() - session.end.getTime()) / tenMinutes);
    var slices = [];

    for (var i = min; i < session.sliceList.length - max; i++) {
      slices.push(session.sliceList[i]);
      slices[slices.length - 1].seconds = 600;
    }

    session.slices = slices;

  }

  function resetVariables(options) {
    chartData = {};
    session = {};
    variables.tooltip = false;
    variables.editable = false;
    if (!options) {
      options = { tooltip: false, editable: false };
    } else {
      for (var propertyName in options) {
        variables[propertyName] = options[propertyName];
      }
    }
  }

  function formatSession(sleepData) {
    var session = JSON.parse(JSON.stringify(sleepData));
    var coeff = 1000 * 60 * 10; //10 minutes
    session.startTime = new Date(sleepData.startDate);
    session.endTime = new Date(sleepData.endDate);
    session.originalStartTime = new Date(sleepData.originalStartDate);
    session.originalEndTime = new Date(sleepData.originalEndDate);
    session.fallAsleepTime = new Date(sleepData.fallAsleepDate);
    session.fallAsleepPeriod = sleepData.fallAsleepPeriod || 0;

    // removes seconds from the times, so the slice calculation can be more accurate
    // at the end these will be returned back
    session.originalStartTimeWithoutSeconds = new Date(new Date(session.originalStartTime.setSeconds(0)));
    session.originalEndTimeWithoutSeconds = new Date(new Date(session.originalEndTime.setSeconds(0)));
    session.startTimeWithoutSeconds = new Date(new Date(session.startTime.setSeconds(0)));
    session.endTimeWithoutSeconds = new Date(new Date(session.endTime.setSeconds(0)));
    session.fallAsleepTimeWithoutSeconds = new Date(new Date(session.fallAsleepTime.setSeconds(0)));

    session.start = variables.editable ? session.originalStartTimeWithoutSeconds : session.startTimeWithoutSeconds;
    session.end = variables.editable ? session.originalEndTimeWithoutSeconds : session.endTimeWithoutSeconds;

    session.startTenMinutes = new Date(Math.floor(session.start.getTime() / coeff) * coeff);
    session.endTenMinutes = new Date(Math.ceil(session.end.getTime() / coeff) * coeff);

    session.startTimeTenMinutes = new Date(Math.ceil(session.startTime.getTime() / coeff) * coeff);
    session.endTimeTenMinutes = new Date(Math.ceil(session.endTime.getTime() / coeff) * coeff);
    session.originalStartTimeTenMinutes = new Date(Math.floor(session.originalStartTime.getTime() / coeff) * coeff);
    session.originalEndTimeTenMinutes = new Date(Math.ceil(session.originalEndTime.getTime() / coeff) * coeff);
    session.fallAsleepTimeTenMinutes = new Date(Math.ceil(session.fallAsleepTime.getTime() / coeff) * coeff);
    return session;
  }

  function clearChart(id) {
    var item = id + " div";
    d3.selectAll(item).remove();
  }

  function drawTooltips(id) {
    var item = `${id} #sleep-session-chart`;
    var chart = d3.select(item);
    var chartWidth = document.querySelectorAll(item)[0].clientWidth;
    var tooltipContainer = chart.append('div').attr('class', 'tooltip-container');
    const tool = document.querySelector('.tooltip-container');
    var fallASleepSliceWidth;
    var sliceWidth = 0;

    // move start time to the end of the fallAsleep
    if (variables.tooltip && document.querySelector('.fall-asleep')) {
      fallASleepSliceWidth = document.querySelector('.fall-asleep').style.width;
      tool.style.width = "calc(100% - " + fallASleepSliceWidth + ")";
    } else {
      tool.style.width = '100%';
    }

    // if the fallAsleep slice width is taking more than 70% of the chart then the tooltip on the left will be align to the right
    const fallASleepSliceWidthInt = document.querySelector('.fall-asleep') ? document.querySelector('.fall-asleep').getBoundingClientRect().width : 0;
    const tooltipLeftClass = ((fallASleepSliceWidthInt / chartWidth) * 100) >= 70 ? 'time-value left align-right' : 'time-value left';

    tooltipContainer.append('span').attr('class', tooltipLeftClass)
      .text(d3.timeFormat(timeFormats.hourMinutesFormatSpace)(session.fallAsleepTime.getTime() > session.start.getTime() ? session.fallAsleepTime : session.start));
    if (fallASleepSliceWidth > sliceWidth) {
    }
    tooltipContainer.append('span').attr('class', 'time-value right').text(d3.timeFormat(timeFormats.hourMinutesFormatSpace)(session.end));
  }

  function drawDraggers() {
    var editLayerWidth = document.getElementById('edit-layer').clientWidth;
    var timeDiff = session.originalEndTimeTenMinutes.getTime() / 1000 - session.originalStartTimeTenMinutes.getTime() / 1000;
    var tenMinutes = 10 * 60 * 1000;
    var widthCoeff = (editLayerWidth) / timeDiff;

    // set and calculate draggers position
    if (!draggers.leftDraggerTime) {
      draggers.leftDraggerTime = session.startTime;
      draggers.leftDraggerTimeTenMinutes = session.startTimeTenMinutes;
      draggers.rightDraggerTime = session.endTime;
      draggers.rightDraggerTimeTenMinutes = session.endTimeTenMinutes;
    }

    // calculate how much draggers are moved on the chart 
    // this is the case if the session is edited
    draggers.leftTimeDiff = draggers.leftDraggerTimeTenMinutes.getTime() / 1000 - session.originalStartTimeTenMinutes.getTime() / 1000;
    draggers.rightTimeDiff = session.originalEndTimeTenMinutes.getTime() / 1000 - draggers.rightDraggerTimeTenMinutes.getTime() / 1000;
    // calculate draggers position
    draggers.leftIndex = Math.ceil((draggers.leftDraggerTimeTenMinutes.getTime() - session.originalStartTimeWithoutSeconds.getTime()) / tenMinutes);
    draggers.rightIndex = session.slices.length - Math.ceil((session.originalEndTimeWithoutSeconds.getTime() - draggers.rightDraggerTimeTenMinutes.getTime()) / tenMinutes);

    var editLayer = d3.select('#edit-layer');
    var editContainer = editLayer.append('div').attr('class', 'edit-container').attr('id', 'edit-container');

    // add overlay that shows when draggers are moved
    var leftEdit = editContainer.append('div').attr('class', 'edit-part left-edit').attr('id', 'left-edit');
    leftEdit.append('div').attr('class', 'white-overlay').attr('id', 'white-overlay-left');;
    var rightEdit = editContainer.append('div').attr('class', 'edit-part right-edit').attr('id', 'right-edit');
    rightEdit.append('div').attr('class', 'white-overlay').attr('id', 'white-overlay-right');

    // add touch field  to draggers
    var leftDragger = leftEdit.append('div').attr('class', 'dragger').attr('id', 'left-dragger');
    leftDragger.append('div').attr('class', 'touch-field left');
    var leftDraggerInnerContainer = leftDragger.append('div').attr('class', 'inner-dragger-container');
    leftDragger.append('div').attr('class', 'circle').html(arrowImage());
    var leftTimeValue = leftDragger.append('div').attr('class', 'time-value').attr('id', 'left-time-value')
      .text(d3.timeFormat(timeFormats.hourMinutesFormatSpace)(draggers.leftDraggerTime));

    var rightDragger = rightEdit.append('div').attr('class', 'dragger').attr('id', 'right-dragger');
    rightDragger.append('div').attr('class', 'touch-field right');
    var rightDraggerInnerContainer = rightDragger.append('div').attr('class', 'inner-dragger-container');
    rightDragger.append('div').attr('class', 'circle').html(arrowImage());
    var rightTimeValue = rightDragger.append('div').attr('class', 'time-value').attr('id', 'right-time-value')
      .text(d3.timeFormat(timeFormats.hourMinutesFormatSpace)(draggers.rightDraggerTime));

    // adds dragger width
    leftEdit.attr('style', 'width: ' + (3 + (widthCoeff * draggers.leftTimeDiff)) + 'px;');
    rightEdit.attr('style', 'width: ' + (3 + (widthCoeff * draggers.rightTimeDiff)) + 'px;');

    // add dragger events here
    addDraggerEvents();
  }

  function addDraggerEvents() {
    var leftDragger = document.getElementById('left-dragger');
    var rightDragger = document.getElementById('right-dragger');
    leftDragger.onmousedown = onMouseDownLeft;
    leftDragger.ontouchstart = onTouchStartLeft;
    rightDragger.onmousedown = onMouseDownRight;
    rightDragger.ontouchstart = onTouchStartRight;

    // for explanation how draggers work 
    // go to onMoveLeftDragger
    // same login is applied to onMoveRightDragger and touch events
    leftDragger.ondragstart = function () {
      return false;
    };

    rightDragger.ondragstart = function () {
      return false;
    };
  }

  function onMouseDownLeft(event) {
    event.preventDefault();

    var leftDragger = document.getElementById('left-dragger');
    leftShiftX = event.clientX - leftDragger.getBoundingClientRect().left;

    document.addEventListener('mousemove', onMoveLeftDragger);
    document.addEventListener('mouseup', onMouseUp);

    function onMouseUp() {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMoveLeftDragger);
    }
  }

  function onTouchStartLeft(event) {
    event.preventDefault();

    var leftDragger = document.getElementById('left-dragger');
    var clientX = event.changedTouches[0].clientX;
    leftShiftX = clientX - leftDragger.getBoundingClientRect().left;

    document.addEventListener('touchmove', onMoveLeftDraggerTouch);
    document.addEventListener('touchend', onTouchEnd);

    function onTouchEnd() {
      document.removeEventListener('touchend', onTouchEnd);
      document.removeEventListener('touchmove', onMoveLeftDraggerTouch);
    }
  }

  function onMouseDownRight(event) {
    event.preventDefault();

    var rightDragger = document.getElementById('right-dragger');
    rightShiftX = event.clientX - rightDragger.getBoundingClientRect().left;
    var shiftX = event.clientX - rightDragger.getBoundingClientRect().left;

    document.addEventListener('mousemove', onMoveRightDragger);
    document.addEventListener('mouseup', onMouseUp);

    function onMouseUp() {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMoveRightDragger);
    }
  }

  function onTouchStartRight(event) {
    event.preventDefault();

    var rightDragger = document.getElementById('right-dragger');
    var clientX = event.changedTouches[0].clientX;
    rightShiftX = clientX - rightDragger.getBoundingClientRect().left;

    document.addEventListener('touchmove', onMoveRightDraggerTouch);
    document.addEventListener('touchend', onTouchEnd);

    function onTouchEnd() {
      document.removeEventListener('touchend', onTouchEnd);
      document.removeEventListener('touchmove', onMoveRightDraggerTouch);
    }
  }


  function onMoveLeftDragger(event) {
    var leftDragger = document.getElementById('left-dragger');
    var rightDragger = document.getElementById('right-dragger');
    var editParentContainer = document.getElementById('edit-container');
    var chartWidth = document.getElementById('edit-layer').clientWidth;
    var timeDiff = session.originalEndTimeTenMinutes.getTime() / 1000 - session.originalStartTimeTenMinutes.getTime() / 1000;
    var widthCoeff = (chartWidth) / timeDiff;
    var tenMinutes = 10 * 60;
    var clientX = event.clientX || event.changedTouches[0].clientX;

    // get new dragger position
    var newWidth = clientX - leftShiftX - editParentContainer.getBoundingClientRect().left;

    if (newWidth < 0) {
      newWidth = 0;
    }

    var leftIndex = Math.floor(newWidth / (widthCoeff * tenMinutes));
    newWidth = (widthCoeff * tenMinutes) * leftIndex;

    // gets date that will be displayed above the dragger
    var tempLeftDate;
    if (newWidth === 0) {
      tempLeftDate = session.start;
    } else {
      tempLeftDate = new Date(removeMiliseconds(session.originalStartTimeTenMinutes.getTime() + leftIndex * tenMinutes * 1000));
    }

    var leftTimeValue = d3.select('#left-time-value');
    var leftEdit = d3.select('#left-edit');

    // if the difference between right dragger position and moved left dragger is above 4h
    // set left dragger time to temp left dragger time
    // round dragger time to 10 minutes
    // calculate the difference between the dragger position and the original sleep session time (how much the dragger is moved compared to the start of the session)
    // ------------------------------------------------------
    // if the difference between right dragger position and moved left dragger is above 4h
    // calculate the left dragger time
    // round dragger time to 10 minutes
    // calculate the difference between the dragger position and the original sleep session time (how much the dragger is moved compared to the start of the session)
    if ((draggers.rightDraggerTime.getTime() / 1000 - tempLeftDate.getTime() / 1000) > FOUR_HOURS) {
      leftDragger.className = "dragger";
      rightDragger.className = "dragger";
      draggers.leftDraggerTime = tempLeftDate;
      draggers.leftDraggerTimeTenMinutes = new Date(Math.floor(draggers.leftDraggerTime.getTime() / (tenMinutes * 1000)) * (tenMinutes * 1000));
      draggers.leftTimeDiff = draggers.leftDraggerTime.getTime() / 1000 - session.originalStartTimeTenMinutes.getTime() / 1000;
      leftTimeValue.text(d3.timeFormat(timeFormats.hourMinutesFormatSpace)(draggers.leftDraggerTime));
      leftEdit.attr('style', 'width: ' + (3 + newWidth) + 'px;');
    } else {
      draggers.leftDraggerTime = new Date(draggers.rightDraggerTime.getTime() - FOUR_HOURS * 1000);
      draggers.leftDraggerTimeTenMinutes = new Date(Math.floor(draggers.leftDraggerTime.getTime() / (tenMinutes * 1000)) * (tenMinutes * 1000));
      draggers.leftTimeDiff = draggers.leftDraggerTimeTenMinutes.getTime() / 1000 - session.originalStartTimeTenMinutes.getTime() / 1000;
      leftTimeValue.text(d3.timeFormat(timeFormats.hourMinutesFormatSpace)(draggers.leftDraggerTime));
      leftEdit.attr('style', 'width: ' + (3 + (widthCoeff * draggers.leftTimeDiff)) + 'px;');
    }
  }

  function onMoveLeftDraggerTouch(event) {
    var leftDragger = document.getElementById('left-dragger');
    var rightDragger = document.getElementById('right-dragger');
    var editParentContainer = document.getElementById('edit-container');
    var chartWidth = document.getElementById('edit-layer').clientWidth;
    var timeDiff = session.originalEndTimeTenMinutes.getTime() / 1000 - session.originalStartTimeTenMinutes.getTime() / 1000;
    var widthCoeff = (chartWidth) / timeDiff;
    var tenMinutes = 10 * 60;

    if (event.target.className.includes('left')) {
      var clientX = event.clientX || getTouchClientX(event, 'left');

      var newWidth = clientX - leftShiftX - editParentContainer.getBoundingClientRect().left;

      if (newWidth < 0) {
        newWidth = 0;
      }

      var leftIndex = Math.floor(newWidth / (widthCoeff * tenMinutes));
      newWidth = (widthCoeff * tenMinutes) * leftIndex;

      var tempLeftDate;
      if (newWidth === 0) {
        tempLeftDate = session.start;
      } else {
        tempLeftDate = new Date(removeMiliseconds(session.originalStartTimeTenMinutes.getTime() + leftIndex * tenMinutes * 1000));
      }

      var leftTimeValue = d3.select('#left-time-value');
      var leftEdit = d3.select('#left-edit');

      if ((draggers.rightDraggerTime.getTime() / 1000 - tempLeftDate.getTime() / 1000) > FOUR_HOURS) {
        leftDragger.className = "dragger";
        rightDragger.className = "dragger";
        draggers.leftDraggerTime = tempLeftDate;
        draggers.leftDraggerTimeTenMinutes = new Date(Math.floor(draggers.leftDraggerTime.getTime() / (tenMinutes * 1000)) * (tenMinutes * 1000));
        draggers.leftTimeDiff = draggers.leftDraggerTime.getTime() / 1000 - session.originalStartTimeTenMinutes.getTime() / 1000;
        leftTimeValue.text(d3.timeFormat(timeFormats.hourMinutesFormatSpace)(draggers.leftDraggerTime));
        leftEdit.attr('style', 'width: ' + (3 + newWidth) + 'px;');
      } else {
        draggers.leftDraggerTime = new Date(draggers.rightDraggerTime.getTime() - FOUR_HOURS * 1000);
        draggers.leftDraggerTimeTenMinutes = new Date(Math.floor(draggers.leftDraggerTime.getTime() / (tenMinutes * 1000)) * (tenMinutes * 1000));
        draggers.leftTimeDiff = draggers.leftDraggerTimeTenMinutes.getTime() / 1000 - session.originalStartTimeTenMinutes.getTime() / 1000;
        leftTimeValue.text(d3.timeFormat(timeFormats.hourMinutesFormatSpace)(draggers.leftDraggerTime));
        leftEdit.attr('style', 'width: ' + (3 + (widthCoeff * draggers.leftTimeDiff)) + 'px;');
      }
    }
  }

  function onMoveRightDragger(event) {
    var leftDragger = document.getElementById('left-dragger');
    var rightDragger = document.getElementById('right-dragger');
    var editParentContainer = document.getElementById('edit-container');
    var chartWidth = document.getElementById('edit-layer').clientWidth;
    var timeDiff = session.originalEndTimeTenMinutes.getTime() / 1000 - session.originalStartTimeTenMinutes.getTime() / 1000;
    var widthCoeff = (chartWidth) / timeDiff;
    var tenMinutes = 10 * 60;

    var clientX = event.clientX || event.changedTouches[0].clientX;

    var newWidth = clientX - rightShiftX - editParentContainer.getBoundingClientRect().left;
    newWidth = chartWidth - newWidth;
    if (newWidth < 0) {
      newWidth = 0;
    }

    var rightIndex = session.slices.length - Math.floor(newWidth / (widthCoeff * tenMinutes));
    newWidth = (widthCoeff * tenMinutes) * (session.slices.length - rightIndex);

    var tempRightDate;
    if (newWidth === 0) {
      tempRightDate = session.end;
    } else {
      tempRightDate = new Date(removeMiliseconds(session.originalEndTimeTenMinutes.getTime() - (session.slices.length - rightIndex) * tenMinutes * 1000));
    }

    var rightTimeValue = d3.select('#right-time-value');
    var rightEdit = d3.select('#right-edit');

    if ((tempRightDate.getTime() / 1000 - draggers.leftDraggerTime.getTime() / 1000) > FOUR_HOURS) {
      leftDragger.className = "dragger";
      rightDragger.className = "dragger";
      draggers.rightDraggerTime = tempRightDate;
      draggers.rightDraggerTimeTenMinutes = new Date(Math.floor(draggers.rightDraggerTime.getTime() / (tenMinutes * 1000)) * (tenMinutes * 1000));
      draggers.rightTimeDiff = draggers.rightDraggerTime.getTime() / 1000 - session.originalStartTimeTenMinutes.getTime() / 1000;
      rightTimeValue.text(d3.timeFormat(timeFormats.hourMinutesFormatSpace)(draggers.rightDraggerTime));
      rightEdit.attr('style', 'width: ' + (3 + newWidth) + 'px;');
    } else {
      draggers.rightDraggerTime = new Date(draggers.leftDraggerTime.getTime() + FOUR_HOURS * 1000);
      draggers.rightDraggerTimeTenMinutes = new Date(Math.floor(draggers.rightDraggerTime.getTime() / (tenMinutes * 1000)) * (tenMinutes * 1000));
      draggers.rightTimeDiff = session.originalEndTimeTenMinutes.getTime() / 1000 - draggers.rightDraggerTime.getTime() / 1000;
      rightTimeValue.text(d3.timeFormat(timeFormats.hourMinutesFormatSpace)(draggers.rightDraggerTime));
      rightEdit.attr('style', 'width: ' + (3 + (widthCoeff * draggers.rightTimeDiff)) + 'px;');
    }
  }

  function onMoveRightDraggerTouch(event) {
    var leftDragger = document.getElementById('left-dragger');
    var rightDragger = document.getElementById('right-dragger');
    var editParentContainer = document.getElementById('edit-container');
    var chartWidth = document.getElementById('edit-layer').clientWidth;
    var timeDiff = session.originalEndTimeTenMinutes.getTime() / 1000 - session.originalStartTimeTenMinutes.getTime() / 1000;
    var widthCoeff = (chartWidth) / timeDiff;
    var tenMinutes = 10 * 60;

    if (event.target.className.includes('right')) {
      var clientX = event.clientX || getTouchClientX(event, 'right');

      var newWidth = clientX - rightShiftX - editParentContainer.getBoundingClientRect().left;
      newWidth = chartWidth - newWidth;
      if (newWidth < 0) {
        newWidth = 0;
      }

      var rightIndex = session.slices.length - Math.floor(newWidth / (widthCoeff * tenMinutes));
      newWidth = (widthCoeff * tenMinutes) * (session.slices.length - rightIndex);

      var tempRightDate;
      if (newWidth === 0) {
        tempRightDate = session.end;
      } else {
        tempRightDate = new Date(removeMiliseconds(session.originalEndTimeTenMinutes.getTime() - (session.slices.length - rightIndex) * tenMinutes * 1000));
      }

      var rightTimeValue = d3.select('#right-time-value');
      var rightEdit = d3.select('#right-edit');

      if ((tempRightDate.getTime() / 1000 - draggers.leftDraggerTime.getTime() / 1000) > FOUR_HOURS) {
        leftDragger.className = "dragger";
        rightDragger.className = "dragger";
        draggers.rightDraggerTime = tempRightDate;
        draggers.rightDraggerTimeTenMinutes = new Date(Math.floor(draggers.rightDraggerTime.getTime() / (tenMinutes * 1000)) * (tenMinutes * 1000));
        draggers.rightTimeDiff = draggers.rightDraggerTime.getTime() / 1000 - session.originalStartTimeTenMinutes.getTime() / 1000;
        rightTimeValue.text(d3.timeFormat(timeFormats.hourMinutesFormatSpace)(draggers.rightDraggerTime));
        rightEdit.attr('style', 'width: ' + (3 + newWidth) + 'px;');
      } else {
        draggers.rightDraggerTime = new Date(draggers.leftDraggerTime.getTime() + FOUR_HOURS * 1000);
        draggers.rightDraggerTimeTenMinutes = new Date(Math.floor(draggers.rightDraggerTime.getTime() / (tenMinutes * 1000)) * (tenMinutes * 1000));
        draggers.rightTimeDiff = session.originalEndTimeTenMinutes.getTime() / 1000 - draggers.rightDraggerTime.getTime() / 1000;
        rightTimeValue.text(d3.timeFormat(timeFormats.hourMinutesFormatSpace)(draggers.rightDraggerTime));
        rightEdit.attr('style', 'width: ' + (3 + (widthCoeff * draggers.rightTimeDiff)) + 'px;');
      }
    }
  }

  function removeMiliseconds(time) {
    return Math.floor(time / 1000) * 1000;
  }

  function arrowImage() {
    return `<svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.20711 13.8487C8.59763 13.4582 8.59763 12.825 8.20711 12.4345L2.91421 7.1416L8.20711 1.84871C8.59763 1.45818 8.59763 0.825019 8.20711 0.434495C7.81658 0.0439703 7.18342 0.0439703 6.79289 0.434495L0.792893 6.4345C0.402369 6.82502 0.402369 7.45818 0.792893 7.84871L6.79289 13.8487C7.18342 14.2392 7.81658 14.2392 8.20711 13.8487Z" fill="#001D34"/>
    <path d="M12.7929 0.434496C12.4024 0.825021 12.4024 1.45819 12.7929 1.84871L18.0858 7.1416L12.7929 12.4345C12.4024 12.825 12.4024 13.4582 12.7929 13.8487C13.1834 14.2392 13.8166 14.2392 14.2071 13.8487L20.2071 7.84871C20.5976 7.45819 20.5976 6.82502 20.2071 6.4345L14.2071 0.434496C13.8166 0.043972 13.1834 0.043972 12.7929 0.434496Z" fill="#001D34"/>
    </svg>`;
  }

  function getTouchClientX(event, side) {
    for (i = 0; i < event.changedTouches.length; i++) {
      if (event.changedTouches[i].target.className.includes(side)) {
        return event.changedTouches[i].clientX;
      }
    }
  }

  // adds seconds to the date
  function addSeconds(date, seconds) {
    // Making a copy with the Date() constructor
    const dateCopy = new Date(date);
    dateCopy.setSeconds(date.getSeconds() + seconds);
    return dateCopy;
  }
})();
